import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { Link, useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { messageHandler } from '../../../Product/common/socketHandler'

import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Timer from '../../../Product/common/timer'
import {
    currencyFormat,
    dateFormatFrontDay,
    handleRedirectInternal,
} from '../../../Product/common/components'
import DonationModal from '../DonationModal'
import DonationFunction from '../DonationModal/DonationFunction'
import { setAuthToken } from '../../../Product/common/api'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let auctionid = props.match.params.id.includes('?product_id=')
        ? props.match.params.id.split('?product_id=')[0]
        : props.match.params.id

    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 12,
        lotof: auctionid,
        limit: 24,
        market_status: 'open',
    })
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    // const classes = useStyles()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [value, setValue] = React.useState(
        params.get('value') ? parseInt(params.get('value')) : 1,
    )

    // useEffect(() => {
    //     if (auctionid) {
    //         auctionid = auctionid.includes('?product=')
    //             ? auctionid.split('?product=')[0]
    //             : auctionid
    //     }
    // }, [auctionid])

    useEffect(() => {
        const params1 = new URLSearchParams(window.location.search.substring(1))
        if (params1.get('product_id')) {
            setSelectedProduct(params1.get('product_id'))
        }
    }, [window.location.search])

    const handleChange = (event, newValue) => {
        if (newValue == 2) {
            if (isAuthenticated) {
                setValue(newValue)
            } else {
                handleRedirectInternal(history, 'login')
            }
        } else if (newValue == 3) {
            if (isAuthenticated) {
                handleRedirectInternal(
                    history,
                    `ticketing/${auctionData.stage_id}/${auctionData.id}`,
                )
            } else {
                handleRedirectInternal(history, 'login')
            }
        } else if (newValue == 4) {
            handleRedirectInternal(history, 'login')
        } else setValue(newValue)
    }

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid }, 'auctionView')
    }, [isAuthenticated])

    useEffect(() => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        getLotList(subLotSearch, 'auctionView')
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        setLoading(true)
        if (search_allauctionproducts.from === 'auctionView') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }
    }, [edit_auction])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt charityAuctionWrapper">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                            <div className="co-breadcrumb mb-0">
                                <Link to="/auctions">Search</Link> /{' '}
                                <b style={{ color: 'black' }}>{auctionData.title}</b>
                            </div>
                            <div className="timer-wrapper mnPgTimer">
                                <div className="li-timer">
                                    <Timer
                                        date_added={auctionData.date_added}
                                        date_closed={auctionData.date_closed}
                                        withText={1}
                                        icon={true}
                                        endText={'Ends in' + ':'}
                                        startText={'Starts in' + ':'}
                                        // theme="multiseller"
                                    ></Timer>
                                </div>
                            </div>
                        </div>
                        <AppBar position="static" className="eventTabs">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                            >
                                <Tab label="Overview" {...a11yProps(0)} />
                                <Tab label="Browse Items" {...a11yProps(1)} />
                                <Tab label="Donate" {...a11yProps(2)} />
                                {isAuthenticated ? null : (
                                    <Tab label="Register" {...a11yProps(3)} />
                                )}
                                {auctionData.stage_id > 0 ? (
                                    <Tab label="Buy Tickets" {...a11yProps(4)} />
                                ) : (
                                    ''
                                )}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ProductAuctionTop
                                auctionData={auctionData}
                                product_id={auctionid}
                                executeScroll={executeScroll}
                                makeDonation={() => setValue(2)}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                <SubLots
                                    selectedPostion={handleClick}
                                    handleClick={handleClick}
                                    title={auctionData.title}
                                    auctionid={auctionid}
                                    lotData={subLotData}
                                    subLotDataCount={subLotDataCount}
                                    subLotSearch={subLotSearch}
                                    setSubLotSearch={setSubLotSearch}
                                    auctionView={auctionView}
                                    setAuctionView={setAuctionView}
                                />
                                <MultisellerSlider
                                    theme="charity"
                                    type="right"
                                    selectedLot={selectedProduct}
                                    handleClose={handleClose}
                                />
                            </>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className="donPopTab">
                                <DonationModal
                                    open={value == 2}
                                    id={auctionData.id}
                                    handleClose={() => setValue(0)}
                                />
                            </div>
                            {/* <DonationFunction id={auctionData.id} handleClose={() => setValue(0)} /> */}
                        </TabPanel>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
