import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import * as Yup from 'yup'
import Popup from '../../Product/components/organisms/Popup'
import { mapData } from '../../Product/common/components'
import { request_refund_options } from '../../utils'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import pluginContext from '../../Product/context/plugin/pluginContext'
import alertContext from '../../Product/context/alert/alertContext'
const Dispute = ({
    from_id,
    to_id,
    lot_id,
    auctionid,
    invoice_id,
    setTrigger,
    trigger,
    refund_request_notes,
    seller_refund_accept,
}) => {
    const { requestRefund, responseStatus, clearResponse } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [openDispute, setOpenDispute] = useState(false)
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        subject: Yup.string().required('Required!'),
        message: Yup.string().required('Required!'),
        termCheck: Yup.boolean()
            .oneOf([true], 'Please accept Refund Policies')
            .required('Please accept Refund Policies'),
    })
    const formik = useFormik({
        initialValues: {
            site_id: global?.storeDetails?.site_id,
            lot_id: lot_id,
            invoice_id,
            reason: '',
            termCheck: false,
            message: '',
            subject: '',
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(
                'llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll',
                lot_id,
                formik.values,
            )
            formik.values.reason = values.subject == 'OTHER' ? values.message : values.subject
            requestRefund(values)
            setLoading(true)
        },
    })

    useEffect(() => {
        if (formik.values.subject !== 'OTHER') {
            formik.setFieldValue('message', formik.values.subject)
        } else {
            formik.setFieldValue('message', '')
        }
    }, [formik.values.subject])

    const fields = [
        {
            label: 'Reasons',
            placeholder: 'Select a reason',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: request_refund_options,
            name: 'subject',
            formik,
        },
        {
            label: 'Message',
            placeholder: 'Enter Your Message Here...',
            type: 'textarea',
            class: formik.values.subject == 'OTHER' ? 'col-12 col-sm-6' : 'd-none',
            name: 'message',
            formik,
        },
        {
            label: 'All refunds are subject to compliance with refund policy, and will be completed after Mobius Auctions reviews the item. No exceptions will be made outside of the standard refund policy. All refunds requests will be decided at the discretion of Mobius Auctions. Abuse of the refund system may result in refund denials or account suspension.',
            name: 'termCheck',
            type: 'checkbox',
            class: 'col-md-12',
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'requestRefund') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message.message, responseStatus.status)
                    formik.resetForm()
                    setOpenDispute(false)
                    setTrigger(!trigger)
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                }
            }
        }
        clearResponse()
    }, [responseStatus])

    return (
        <div>
            <SecondaryButton
                label={refund_request_notes ? 'View Request Refund' : 'Request Refund'}
                // btnSize="mt-2"
                onClick={() => setOpenDispute(true)}
            />

            <Popup
                open={openDispute}
                handleClose={(e) => setOpenDispute(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Request Refund"
            >
                <div className="row justify-content-center">
                    {refund_request_notes ? (
                        <>
                            <div className="col-12 d-flex flexWrap">
                                <p
                                    style={{
                                        whiteSpace: 'nowrap',
                                        minWidth: '188px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Requested Refund Reason:{' '}
                                </p>
                                <div style={{ textAlign: 'left' }}>
                                    {refund_request_notes + ' '}
                                </div>
                            </div>
                            <div className="col-12 d-flex">
                                <p style={{ minWidth: '188px', textAlign: 'left' }}>Status: </p>
                                <p style={{ textAlign: 'left' }}>
                                    {seller_refund_accept == 0
                                        ? 'Refund Request Submited To Admin. '
                                        : seller_refund_accept == 4 || seller_refund_accept == 6
                                        ? 'Admin Accepted Your Refund Request. '
                                        : seller_refund_accept == 2
                                        ? 'Admin Rejected Your Request Refund. '
                                        : ''}
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            {mapData(fields)}
                            <div className="col-4">
                                <PrimaryButton
                                    label={loading ? 'Loading...' : 'Submit'}
                                    className="mbv-btn"
                                    onClick={formik.handleSubmit}
                                    disabled={refund_request_notes || loading ? true : false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Popup>
        </div>
    )
}

export default Dispute
