import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getTransactions } from '../../Services/Invoice.service'
import { buyerPurchaseDashboard } from '../../Services/Bidding.service'
import moment from 'moment-timezone'
import PaginationComponent from '../Pagination'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'

const AllTransactions = () => {
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const history = useHistory()
    useEffect(() => {
        const getData = async () => {
            let { data } = await buyerPurchaseDashboard({ sh_limit: 10, page, order: 1 }) //await getTransactions({ page, sh_limit: 10 });
            if (data.success === 'yes') {
                setData([...data.results])
                setTotal(data.total_pagecnt)
            }
        }
        getData()
    }, [page])

    const changePage = (number) => {
        document.getElementById('heading').scrollIntoView()
        setPage(parseInt(number))
    }

    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover" id="table">
                    <thead>
                        <tr>
                            <th className="numCell">#</th>
                            <th>Item Name</th>
                            <th>Item Id</th>
                            <th>Amount</th>
                            {/* <th>Payment Mode</th> */}
                            <th>Purchased On</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.common_invoice}</td>
                                    <td>{item.title}</td>
                                    <td>{item.id}</td>
                                    <td>US$ {parseFloat(item.sprice).toFixed(2)}</td>
                                    {/* <td>{item.pay_type}</td> */}
                                    <td>
                                        {moment(item.buynowpaid_date)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY')}{' '}
                                        ET
                                    </td>
                                    {/* <td>{item.paid === 1 ? "Paid" : "Failed"}</td> */}
                                    <td>{'Paid'}</td>
                                    {/* {item.paid !== 1 ? (
                    <td>
                      <a className="ub-btn">Retry Payment</a>
                    </td>
                  ) : ( */}
                                    <td>
                                        <PrimaryButton
                                            label="View Invoice"
                                            className="mbv-btn"
                                            to={`/invoice/${item.common_invoice}`}
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${item.common_invoice}`,
                                                )
                                            }
                                        />
                                    </td>
                                    {/* )} */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                onPageChange={changePage}
                totalProductCount={parseInt(total)}
                currentPage={parseInt(page || 1)}
                resultToShow={10}
            />
        </>
    )
}

export default AllTransactions
