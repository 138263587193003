import React, { useReducer, useState } from 'react'
import CartContext from './cartContext'
import CartReducer from './cartReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, GET_ALL_CART_PRODUCTS, CLEAR_RESPONSE } from './cartTypes'
import { useContext } from 'react'
import authContext from '../auth/authContext'
import alertContext from '../alert/alertContext'
import moment from 'moment'
import FormDialog from '../../components/organisms/Dialog'
const CartState = (props) => {
    const initialState = {
        responseStatus: null,
        buyer_cartitems: {
            results: [],
        },
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const { user } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const [popupchecked, setPopupChecked] = useState(false)
    const addToCart = async (formData) => {
        const from = 'addToCart'
        if (
            global?.storeConfigration?.buy_now_with_qty?.value &&
            global?.storeConfigration?.qty_input_slider?.value != 1
        ) {
            formData.qty = 1
        }
        if (global?.storeConfigration?.restrict_shipping_states?.value) {
            let temp = global?.storeConfigration?.restrict_shipping_states?.value?.split(',')
            let restrict_state = temp.find(
                (val) => val.toLowerCase().trim() == user?.state?.toLowerCase()?.trim(),
            )

            if (restrict_state) {
                return setPopupChecked(true)
            }
        }
        if (global?.storeConfigration?.states_25_years?.value) {
            if (formData.manufacture_date) {
                var years = moment().diff(formData.manufacture_date, 'years')
                if (years < 25) {
                    let temp_25 = global?.storeConfigration?.states_25_years?.value?.split(',')
                    let restrict_state_25 = temp_25.find(
                        (val) => val.toLowerCase().trim() == user?.state?.toLowerCase()?.trim(),
                    )

                    if (restrict_state_25) {
                        return setPopupChecked(true)
                    }
                }
            }
        }

        if (global?.storeConfigration?.states_20_years?.value) {
            if (formData.manufacture_date) {
                var years = moment().diff(formData.manufacture_date, 'years')
                if (years < 20) {
                    let temp_20 = global?.storeConfigration?.states_20_years?.value?.split(',')
                    let restrict_state_20 = temp_20.find(
                        (val) => val.toLowerCase().trim() == user?.state?.toLowerCase()?.trim(),
                    )

                    if (restrict_state_20) {
                        return setPopupChecked(true)
                    }
                }
            }
        }

        if (global?.storeConfigration?.states_30_years?.value) {
            if (formData.manufacture_date) {
                var years = moment().diff(formData.manufacture_date, 'years')
                if (years < 30) {
                    let temp_30 = global?.storeConfigration?.states_30_years?.value?.split(',')
                    let restrict_state_30 = temp_30.find(
                        (val) => val.toLowerCase().trim() == user?.state?.toLowerCase()?.trim(),
                    )

                    if (restrict_state_30) {
                        return setPopupChecked(true)
                    }
                }
            }
        }

        try {
            const [res] = await Promise.all([apiCall('get', 'addtocart', formData)])
            if (res.data.status === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Successfully added to cart' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeFromCart = async (formData) => {
        const from = 'removeFromCart'
        try {
            const [res] = await Promise.all([apiCall('get', 'removefromcart', formData)])
            if (res.data.success) {
                resp.commonResponse(
                    { status: 'success', message: 'Item removed successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getUserCart = async (formData = {}, fromVariable) => {
        const from = 'getUserCart'
        formData.auction_io = 1
        try {
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([apiCall('get', 'getUserCart', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_CART_PRODUCTS,
                    payload: {
                        results: res.data.data.length ? res.data.data : [],
                        from: fromVariable,
                    },
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateFromCart = async (formData) => {
        const from = 'updateusercart'
        formData.auction_io = 1
        const [res] = await Promise.all([apiCall('post', 'updateCart', formData)])
        if (res.data.status == 'yes') {
            resp.commonResponse({ status: 'success', message: res.data.message }, from)
        } else {
            resp.commonResponse({ status: 'error', message: res.data.message }, from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CartContext.Provider
            value={{
                responseStatus: state.responseStatus,
                buyer_cartitems: state.buyer_cartitems,
                clearResponse,
                addToCart,
                removeFromCart,
                getUserCart,
                updateFromCart,
            }}
        >
            {props.children}
            <FormDialog
                open={popupchecked}
                function={() => {
                    setPopupChecked(false)
                }}
                size="md"
                className="save-search-modal restrictedState"
                title=""
                closeBtn={true}
            >
                <h5>
                    Purchase not allowed at the moment. Please refer to{' '}
                    <a
                        href={
                            window.location.origin.includes('auction_preview')
                                ? `/${global?.storeDetails?.id}/faq`
                                : '/faq'
                        }
                    >
                        FAQ
                    </a>
                </h5>
            </FormDialog>
        </CartContext.Provider>
    )
}

export default CartState
