import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import { setAuthToken } from '../../../Product/common/api'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { allCondition } from '../../../utils'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const allCategory = [
    {
        id: 1,
        description: 'Stationary',
        avatar: 'file_upload-1615977245184_440403.jpeg',
        level: 1,
        level_id: 2,
        active: 1,
    },
    {
        id: 2,
        description: 'Machinery',
        avatar: 'file_upload-1630584314773_854850.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 3,
        description: 'Mixed (Heavy & Light Steel Scrap)',
        avatar: 'file_upload-1616073092637_128721.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 4,
        description: 'Vehicles',
        avatar: 'file_upload-1630584325833_251368.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 5,
        description: 'IT Assets',
        avatar: 'file_upload-1630584340803_303810.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 6,
        description: 'Electronics',
        avatar: 'file_upload-1630584350441_373035.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 7,
        description: 'Furnitures',
        avatar: 'file_upload-1630584359979_964177.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 8,
        description: 'Mixed scrap',
        avatar: 'file_upload-1630584370950_532450.png',
        level: 0,
        level_id: 0,
        active: 1,
    },
    {
        id: 9,
        description: 'Waste Battery',
        avatar: 'file_upload-1616073196556_917449.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 10,
        description: 'Empty Drums - Steel / Plastic Drum',
        avatar: 'file_upload-1627485556057_469831.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 11,
        description: 'Scrap Tyres',
        avatar: 'file_upload-1616073237472_292697.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 12,
        description: 'Radiator - Copper',
        avatar: 'file_upload-1616073256982_914667.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 13,
        description: 'Radiator - Aluminium',
        avatar: 'file_upload-1616073272458_306092.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 14,
        description: 'Radiator - Metal',
        avatar: 'file_upload-1616073285085_437865.jpeg',
        level: 0,
        level_id: 0,
        active: 2,
    },
    {
        id: 15,
        description: 'Mobile',
        avatar: 'file_upload-1615977818919_421258.jpeg',
        level: 1,
        level_id: 2,
        active: 1,
    },
    {
        id: 16,
        description: 'Light',
        avatar: '',
        level: 1,
        level_id: 4,
        active: 1,
    },
    {
        id: 17,
        description: 'Sub Category - Radiator',
        avatar: '',
        level: 1,
        level_id: 12,
        active: 2,
    },
    {
        id: 26,
        description: 'varsha',
        avatar: 'file_upload-1624522858527_160348.png',
        level: 1,
        level_id: 11,
        active: 2,
    },
    {
        id: 27,
        description: 'varsha',
        avatar: 'file_upload-1624522858527_160348.png',
        level: 1,
        level_id: 11,
        active: 2,
    },
    {
        id: 29,
        description: 'Heavy',
        avatar: '',
        level: 1,
        level_id: 4,
        active: 1,
    },
    {
        id: 30,
        description: 'Desktops and Laptops',
        avatar: '',
        level: 1,
        level_id: 5,
        active: 1,
    },
    {
        id: 31,
        description: 'Monitors, Printers and the peripherals',
        avatar: '',
        level: 1,
        level_id: 5,
        active: 1,
    },
    {
        id: 32,
        description: 'Accessories',
        avatar: '',
        level: 1,
        level_id: 5,
        active: 1,
    },
    {
        id: 33,
        description: 'Television',
        avatar: '',
        level: 1,
        level_id: 6,
        active: 1,
    },
    {
        id: 34,
        description: 'DVD Players',
        avatar: '',
        level: 1,
        level_id: 6,
        active: 1,
    },
    {
        id: 35,
        description: 'Sound System',
        avatar: '',
        level: 1,
        level_id: 6,
        active: 1,
    },
    {
        id: 36,
        description: 'Scrap Wood',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 37,
        description: 'Waste paper',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 38,
        description: 'Waste plastic',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 39,
        description: 'Metal waste',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 40,
        description: 'Used wire rope',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 41,
        description: 'Used oil drum',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 42,
        description: 'Used equipments',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 43,
        description: 'Waste Oil',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 44,
        description: 'Waste Batteries',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 45,
        description: 'Redundant spares',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 46,
        description: 'Mobile and Landline Phones',
        avatar: '',
        level: 1,
        level_id: 5,
        active: 1,
    },
    {
        id: 47,
        description: 'Waste Rubber',
        avatar: '',
        level: 1,
        level_id: 8,
        active: 1,
    },
    {
        id: 48,
        description: 'Test Parent Category ',
        avatar: 'file_upload-1631776266506_704999.jpeg',
        level: 0,
        level_id: 0,
        active: 1,
    },
]

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts, getAllbrands, brandsproduct } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user, saveSearch } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const [auctionTimer, setAuctionTimer] = useState('')
    const [states, setStates] = useState([])
    const [brands, setBrands] = useState([])

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    setAuctionTimer(data.dTime)
                }
            })
        }
    }, [socket])

    useEffect(() => {
        if (brandsproduct.length > 0) {
            setBrands(brandsproduct)
        } else {
            setBrands([])
        }
    }, [brandsproduct])

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: '',
            toprice: '',
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            auction_io_zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            from: 'auction_only',
            closed_auction_list: 0,
            auction_io: 1,
            view_type: global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? 0 : 2,
            conditionunique: [],
        },
    })

    if (global.storeConfigration?.need_post_buynow?.value != 0) {
        var auction_type_options = [
            { id: 0, description: 'All Items' },
            { id: 1, description: 'Auction' },
            { id: 2, description: 'Buy Now' },
        ]
    } else {
        var auction_type_options = [{ id: 0, description: 'Auction' }]
    }

    if (global.storeConfigration?.add_extra_fillter?.value == 1) {
        var filterValues = [
            {
                title: 'Auction type',
                type: 'radio',
                noLabel: true,
                name: 'view_type',
                int: 1,
                class: `col-12`,
                item: auction_type_options,
                formik: formik,
            },
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
            {
                title: 'Brands',
                type: 'check',
                name: 'conditionunique',
                options: brands
                    .map((location) => {
                        let conditionChanged = {}
                        conditionChanged.description = location.description
                        conditionChanged.id = location.id
                        conditionChanged.name = location.name
                        conditionChanged.value = location.id
                        conditionChanged.active = 1
                        return conditionChanged
                    })
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .filter((main) => {
                        return true
                    }),
                formik: formik,
            },
            {
                title: 'Location',
                type: 'check',
                name: 'state',
                options: states
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.description = buscat.state
                        busCategoryChanged.name = buscat.state
                        busCategoryChanged.value = buscat.state
                        busCategoryChanged.id = buscat.state
                        busCategoryChanged.active = 1
                        return busCategoryChanged
                    })
                    .sort()
                    .filter((main) => {
                        return true
                    }),
                formik: formik,
            },
        ]

        var extra_filter = [
            {
                type: 'text',
                placeholder: 'From Price',
                class: 'col-6',
                name: 'fromprice',
                formik: formik,
            },
            {
                type: 'text',
                placeholder: 'To Price',
                class: 'col-6',
                name: 'toprice',
                formik: formik,
            },
        ]
    } else if (global.storeConfigration?.disabled_auc_mulsel2?.value != 1) {
        var filterValues = [
            {
                title: 'Auction type',
                type: 'radio',
                noLabel: true,
                name: 'view_type',
                int: 1,
                class: `col-12`,
                item: auction_type_options,
                formik: formik,
            },
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
        ]

        var extra_filter = []
    } else {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
        ]
        var extra_filter = []
    }

    useEffect(() => {
        setLoading(false)
        if (search_allproducts.from === 'searchPage') {
            console.log('search_allproducts', search_allproducts)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalItem(search_allproducts.total_pagecnt)
            setStates(search_allproducts.state.filter((val) => val.state != ''))
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    useEffect(() => {
        setLoading(true)
        // if (formik.values.closed_auction_list == 1) {
        //     formik.values.orderby = 7
        // }
        // else {
        //     formik.values.orderby = 6
        // }
        if (formik.values.view_type == 0) {
            formik.setFieldValue('from', '')
            getAllSearchProducts(formik.values, 'searchPage')
        } else if (formik.values.view_type == 1) {
            formik.setFieldValue('from', 'auction_only')
            if (formik.values.from === 'auction_only') {
                getAllSearchProducts(formik.values, 'searchPage')
            }
        } else if (formik.values.view_type == 2) {
            formik.setFieldValue('from', 'buynow')
            if (formik.values.from === 'buynow') {
                getAllSearchProducts(formik.values, 'searchPage')
            }
        }
    }, [isAuthenticated, formik.values])

    useEffect(() => {
        formik.setFieldValue('page', 1)
    }, [formik.values.view_type])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('auction_io_zipcode', params.get('z') ? params.get('z') : '')
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue('category', params.get('cat') ? [params.get('cat')] : '')
        setAuctionView(params.get('view') ? params.get('view') : 'Grid')
        if (params.get('product')) {
            handleClick(params.get('product'))
        }
    }, [window.location.search])

    useEffect(() => {
        if (global.storeConfigration?.add_extra_fillter?.value == 1) {
            getAllbrands({ site_id: global?.storeDetails?.site_id })
        }
    }, [])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        currentUrlParams.set('cat', '')
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }
    return (
        <Layout props={props}>
            <div className="customContainer mt-5 position-relative">
                {formik?.values.searchbar && isAuthenticated && (
                    <div className="searchSaveCnt">
                        <h5>Showing results for &quot;{formik?.values.searchbar}&quot;</h5>
                        <Button onClick={() => saveSearch({ searchbar: formik?.values.searchbar })}>
                            <span className="material-icons">bookmark_add</span> Save this search
                        </Button>
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                    <h2 className="auctionListTitle">Search</h2>

                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => handleAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => handleAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={formik.values.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                formik.setFieldValue('sh_limit', event.target.value)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                    <Button
                        className="toggleRespDrawer w-100"
                        variant="outlined"
                        onClick={toggleDrawer('bottom', true)}
                    >
                        <span className="material-icons">tune</span>
                        Filters
                    </Button>
                </div>
                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel
                            formik={formik}
                            filterData={Object.keys(extra_filter).length > 0 ? extra_filter : ''}
                            filterValues={filterValues}
                            label={'Price Filter'}
                        />
                        {/* <FilterPanel
                            formik={formik}
                            filterData={extra_filter}
                            filterValues={extra_filter}
                            custom={1}
                        /> */}
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct.length === 0 ? (
                            <NoRecordsFound />
                        ) : (
                            <div className={`lotResults ${auctionView}`}>
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridProduct
                                            data={data}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                            key={index}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            type={data.auction ? 'auction' : 'buynow'}
                                            selectedProduct={selectedProduct}
                                            link={`/gallery/product-view/${data.id}`}
                                            handleClick={handleClick}
                                            timerTheme="multiseller"
                                            sliderTheme="multiseller"
                                            cardTheme="multiseller"
                                            bidStatus={true}
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Place Bid
                                                </>
                                            }
                                            auctionTimer={auctionTimer}
                                            removePopup={true}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            lotData={data}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                            key={index}
                                            selectedProduct={selectedProduct}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                            auctionTimer={auctionTimer}
                                        />
                                    ),
                                )}
                            </div>
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel
                        formik={formik}
                        label={'Price Filter'}
                        filterValues={filterValues}
                        filterData={Object.keys(extra_filter).length > 0 ? extra_filter : ''}
                    />
                </div>
            </Drawer>
            <MultisellerSlider
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
            />
        </Layout>
    )
}

export default AuctionSearchComponent
