/*eslint-disable*/
import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { currencyFormat, handleRedirectInternal } from '../../../../Product/common/components'
import ProductContext from '../../../../Product/context/product/productContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import CartContext from '../../../../Product/context/cart/cartContext'
import { Pagination } from '@material-ui/lab'
import MultisellerSlider from '../../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../../Product/components/atoms/NoRecordsFound'
import { imageError } from '../../../commonFunctions'
import Loader from '../../../../assets/loader'
import CustomSelect from '../../../../Product/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import AddToCart from '../../Cart/AddToCart'
import GridProduct from '../../../../Component/ProductCard/gridProduct'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Button, Tooltip } from '@material-ui/core'

const PosStock = (props) => {
    const productContext = useContext(ProductContext)
    const { seller_allproducts, getAllSellerProducts } = productContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const cartContext = useContext(CartContext)
    const { buyer_cartitems } = cartContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [productdetails, setProductDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [searchValue, setSearchValue] = useState('')
    const [buynowId, setBuynowId] = useState([])
    const [invoiceDetails, setInvoiceDetails] = useState([])

    const [search, setSearch] = useState({
        status: 'open',
        limit: 12,
        page: 1,
        search: '',
        paid: undefined,
        type: 'buynow',
        site_id: global?.storeDetails?.site_id,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleSearch = (event) => {
        event.preventDefault()
        setSearch({
            ...search,
            search: searchValue,
        })
    }

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            getAllSellerProducts(search)
        }
    }, [search, isAuthenticated])
    useEffect(() => {
        setLoading(false)
        if (seller_allproducts.result.length > 0) {
            seller_allproducts.result.map((val) => {
                val.checked = false
            })
            setProductDetails(seller_allproducts.result)
        } else {
            setProductDetails([])
        }
    }, [seller_allproducts])

    const types = [
        {
            value: 'buynow',
            show: 'Buy Now Products',
        },
        {
            value: 'auction',
            show: 'Unpaid Invoices',
        },
        {
            value: 'offline_order',
            show: 'Offline Order',
        },
    ]
    const handleClick = (id) => {
        var arr = buynowId
        var temp = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        setBuynowId(temp)
    }
    useEffect(() => {
        var temp = []
        buynowId.map((val) => {
            productdetails.map((value) => {
                if (value.common_invoice == val) {
                    temp.push(value)
                }
            })
        })
        setInvoiceDetails(temp)
    }, [buynowId])
    const handleTypeChange = (e) => {
        const { name, value } = e.target

        if (value == 'offline_order') {
            handleRedirectInternal(history, `cart?pos=1&type=offline_order`)
        } else {
            setSearch({
                ...search,
                [name]: value,
                paid: value === 'buynow' ? undefined : 0,
                status: value === 'buynow' ? 'open' : 'sold',
            })
        }
    }
    const redirectCheckout = () => {
        handleRedirectInternal(
            history,
            search.type === 'auction'
                ? `cart?pos=1&type=auction&id=${buynowId.join('&id=')}`
                : `cart?pos=1`,
        )
    }
    return (
        <div>
            <div className="posMain">
                <div className="posMainLt">
                    <CustomSelect
                        label="Type *"
                        id="type"
                        value={search.type}
                        name="type"
                        onChange={handleTypeChange}
                        placeholder="Select type"
                    >
                        {types.map((val, key) => (
                            <option key={key} value={val.value}>
                                {val.show}
                            </option>
                        ))}
                    </CustomSelect>
                    <form className="nav-standard-top-area-search">
                        <div className="nav-standard-top-area-search-inner">
                            <input
                                type="text"
                                onChange={(e) => setSearchValue(e.target.value)}
                                name="searchValue"
                                value={searchValue}
                                className="nav-standard-top-area-search-input open-sans"
                                placeholder="Lot ID/Title"
                            />
                            <button
                                onClick={handleSearch}
                                className="lin lin-magnifier subLotSearch nav-standard-top-area-search-sumbit"
                                type="submit"
                            >
                                <span className="material-icons">search</span>
                            </button>
                        </div>
                    </form>
                    {loading ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ) : productdetails.length ? (
                        <>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th className="numCell">Id #</th>
                                            <th>Image</th>
                                            <th className="pdtNameCol">Product Name</th>
                                            <th>Price</th>
                                            <th className="actnBtnCol">Action</th>
                                        </tr>
                                    </thead>
                                    {productdetails.map((item, index) => {
                                        return (
                                            <>
                                                <tbody>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <img
                                                                className="pro-img"
                                                                src={`${global.images_url}product/${item.avatar}`}
                                                                alt={item.title}
                                                                width="65"
                                                                height="65"
                                                                onError={(e) =>
                                                                    (e.target.src = imageError())
                                                                }
                                                            />
                                                        </td>
                                                        <td>{item.title}</td>

                                                        <td>
                                                            <span className="currencyAmt">
                                                                {currencyFormat(
                                                                    search.type === 'buynow'
                                                                        ? item.bprice
                                                                        : item.wprice,
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {search.type === 'buynow' ? (
                                                                <AddToCart
                                                                    price={item.bprice}
                                                                    customLabel={true}
                                                                    addLabel={
                                                                        <span className="material-icons">
                                                                            add_shopping_cart
                                                                        </span>
                                                                    }
                                                                    removeLabel={
                                                                        <span className="material-icons">
                                                                            remove_shopping_cart
                                                                        </span>
                                                                    }
                                                                    id={item.id}
                                                                    qty={item.qty}
                                                                />
                                                            ) : buynowId.includes(
                                                                  item.common_invoice,
                                                              ) ? (
                                                                <Tooltip title="Remove from cart">
                                                                    <Button
                                                                        className="addToCartBtn inCart"
                                                                        onClick={() =>
                                                                            handleClick(
                                                                                item.common_invoice,
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            remove_shopping_cart
                                                                        </span>
                                                                    </Button>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Add to cart">
                                                                    <Button
                                                                        className="addToCartBtn "
                                                                        onClick={() =>
                                                                            handleClick(
                                                                                item.common_invoice,
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            add_shopping_cart
                                                                        </span>
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        )
                                    })}
                                </table>
                            </div>
                            <div className="tableInfo">
                                <div className="">
                                    {'Showing'} {search.limit * (search.page - 1) + 1} -{' '}
                                    {search.limit * search.page > seller_allproducts.length
                                        ? seller_allproducts.length
                                        : search.limit * search.page}{' '}
                                    of {seller_allproducts.length}
                                </div>
                                <Pagination
                                    count={Math.ceil(seller_allproducts.length / search.limit)}
                                    page={search.page}
                                    onChange={onHandlePage}
                                />
                            </div>
                            <MultisellerSlider
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                            />
                        </>
                    ) : (
                        <>
                            <NoRecordsFound />
                        </>
                    )}
                </div>
                <div className="posMainRt">
                    {(
                        search.type === 'buynow'
                            ? buyer_cartitems?.results.length
                            : invoiceDetails.length
                    ) ? (
                        <>
                            {' '}
                            <h3>
                                Ready to checkout(
                                {search.type === 'buynow'
                                    ? buyer_cartitems?.results.length
                                    : invoiceDetails.length}{' '}
                                {`${
                                    (search.type === 'buynow'
                                        ? buyer_cartitems?.results.length
                                        : invoiceDetails.length) > 1
                                        ? 'items'
                                        : 'item'
                                }`}
                                )
                            </h3>
                            <div className="posItems">
                                {(search.type === 'buynow'
                                    ? buyer_cartitems?.results
                                    : invoiceDetails
                                ).map((data, index) => (
                                    <div className="posProduct">
                                        <LazyLoadImage
                                            src={`${
                                                data.content_head1 === '0' || data.store_id === 0
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }${data.avatar}`}
                                            effect="blur"
                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                            height="100%"
                                            width="100%"
                                            className="gridImage"
                                            alt={data.title}
                                            onError={(e) => (e.target.src = imageError())}
                                        />
                                        <div>
                                            {' '}
                                            <h6>
                                                {search.type === 'buynow'
                                                    ? data.project_id
                                                    : data.common_invoice}
                                            </h6>
                                            <h5>{data.title}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                label="Proceed to checkout"
                                onClick={redirectCheckout}
                                disabled={
                                    search.type === 'buynow'
                                        ? !buyer_cartitems?.results.length
                                        : !invoiceDetails.length
                                }
                            />
                        </>
                    ) : (
                        <div className="noRecords">
                            <img src="/assets/svg/searchIcon.svg" alt="Search icon" />
                            <h6>Please add items to proceed with checkout.</h6>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PosStock
