import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const HeaderSearchComponent = (props) => {
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
        zipcode: '',
        miles: '',
        category: [],
    })
    const handleChange = (e) =>
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    let history = useHistory()

    const handleClick = (e) => {
        e.preventDefault()
        // if (window.location.href.includes('/gallery')) {
        //     history.push(
        //         `/gallery?q=${searchValues.searchbar}&z=${searchValues.zipcode}&m=${
        //             searchValues.miles
        //         }&cat=${searchValues.category.length > 0 ? searchValues.category.join(',') : ''}`,
        //     )
        // } else {
        const params = new URLSearchParams(window.location.search)
        history.push(
            `/search?q=${searchValues.searchbar}&z=${searchValues.zipcode}&m=${
                searchValues.miles
            }&cat=${searchValues.category.length > 0 ? searchValues.category.join(',') : ''}&view=${
                params.get('view') ? params.get('view') : ''
            }`,
        )
        //}
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearchValues({
            ...searchValues,
            searchbar: params.get('q') ? params.get('q') : '',
            zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            category: params.get('cat') ? params.get('cat').split(',') : '',
        })
    }, [])
    return (
        <form className="nav-standard-top-area-search">
            <div
                className={`nav-standard-top-area-search-inner ${
                    Boolean(global?.storeConfigration?.disable_zip?.value) ? 'disabledZip' : ''
                }`}
            >
                <div>
                    <input
                        type="text"
                        onChange={handleChange}
                        name="searchbar"
                        value={searchValues.searchbar}
                        className="nav-standard-top-area-search-input open-sans"
                        placeholder="Search"
                    />
                    {Boolean(global?.storeConfigration?.disable_zip?.value) ? null : (
                        <input
                            type="text"
                            onChange={handleChange}
                            name="zipcode"
                            value={searchValues.zipcode}
                            className="nav-standard-top-area-search-input open-sans"
                            placeholder="Zipcode"
                        />
                    )}

                    <select
                        className="custom-select"
                        name="miles"
                        onChange={handleChange}
                        value={searchValues.miles}
                    >
                        <option selected>{`${
                            global?.storeConfigration?.distance_unit?.value || 'Miles'
                        }`}</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <button
                    onClick={handleClick}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </form>
    )
}

export default HeaderSearchComponent
