import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { fromAddress, setKey } from 'react-geocode'
function googlemapfooter(props) {
    const [location, setLoaction] = useState({})

    const style = {
        height: '350px',
        maxWidth: '500px',
        width: '100%',
        border: '10px solid #fff',
        margin: '10px 0 0 auto',
    }
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM',
    })
    setKey('AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM')
    // const center = {
    //     lat: 21.33567,
    //     lng: -157.89566,
    // }

    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        const google_lat_lng = global.storeConfigration?.footer_map?.value
            ? global.storeConfigration.footer_map.value.split(',')
            : [21.33567, -157.89566]
        // console.log('llllllllllllllllllllllllllllllllll', parseFloat(google_lat_lng[0]))
        const bounds = new window.google.maps.LatLngBounds({
            lat: parseFloat(google_lat_lng[0]),
            lng: parseFloat(google_lat_lng[1]),
        })
        map.fitBounds(bounds)
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={style}
                    center={location}
                    zoom={5}
                    onUnmount={onUnmount}
                    onLoad={onLoad}
                >
                    {/* <Marker position={{ lat: 21.33567, lng: -157.89566 }}></Marker> */}
                </GoogleMap>
            ) : null}
        </>
    )
}
export default googlemapfooter
