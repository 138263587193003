import React, { useContext, useEffect, useState } from 'react'
import './MessagePlugin.css'
import { Button, IconButton } from '@material-ui/core'
import FullScreenPopup from '../../../Product/components/organisms/FullScreenPopup'
import FormDialog from '../../../Product/components/organisms/Dialog'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { socket } from '../../../Product/common/socket'
import moment from 'moment'
const MessagePlugin = ({ product }) => {
    const [messagePopup, setMessagePopup] = useState(false)
    const { chat_history, getChatHistory } = useContext(BuyerContext)
    const { user } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const [chatHistory, setChatHistory] = useState([])
    const [messageInput, setMessageInput] = useState('')

    useEffect(() => {
        if (user && product && messagePopup) {
            getChatHistory({ chat_id: product?.user_id, project_id: product?.id })
        }
    }, [user, product, messagePopup])

    useEffect(() => {
        if (chat_history?.length > 0) {
            setChatHistory(chat_history)
        } else {
            setChatHistory([])
        }
    }, [chat_history])

    const [trigger, setTrigger] = useState(false)

    const handleChange = (e) => setMessageInput(e.target.value)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!messageInput.trim()) {
            return false
        }
        var msg = {
            from_id: user?.id,
            to_id: product?.user_id,
            project_id: product?.id,
            message: messageInput.trim(),
            date_sent: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
        }
        socket.emit('insertMessages', msg)
        setAlert('Message sent successfully', 'success')
        setMessageInput('')
    }

    const messageHandler = (data) => {
        // console.log(data, '=============================')
        if (
            (data.from_id == user?.id || data.to_id == user?.id) &&
            parseInt(data.project_id) == parseInt(product?.id ? product.id : 0)
        ) {
            setChatHistory((prev) => [...prev, data])
            // let msgBody = document.getElementById('msgInrBody')
            // msgBody.scrollTop = msgBody.scrollHeight
        }
    }

    useEffect(() => {
        if (user) {
            socket.on('insertMessages_result', (data) => {
                messageHandler(data)
            })
        }

        return () => {
            socket.off('insertMessages_result', (data) => {
                return data
            })
        }
    }, [user])
    return (
        <div className="msgPluginWrapper">
            <div className="">
                <IconButton className="fltMsgBtn" onClick={() => setMessagePopup(true)}>
                    <span className="material-icons">chat</span>
                </IconButton>
            </div>
            <FormDialog
                className="msgPluginDialog"
                open={messagePopup}
                openFunction={() => setMessagePopup(false)}
                aria-labelledby="form-dialog-title"
                title={
                    <>
                        <div className="messagePopupTitle d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="usrPrflCrcle mr-3">
                                    {product?.first_name.charAt(0) + product?.last_name.charAt(0)}
                                </div>
                                <div className="msgrPrlNm">
                                    <h2 className="mb-0">
                                        {product?.first_name + ' ' + product?.last_name}
                                    </h2>
                                    <p>Seller</p>
                                </div>
                            </div>
                            <IconButton onClick={() => setMessagePopup(false)}>
                                <span className="material-icons">clear</span>
                            </IconButton>
                        </div>
                    </>
                }
                maxWidth={'md'}
            >
                <div className="messagePlgBody">
                    <div className="MessageInrBody">
                        {chatHistory.map((val, key) => (
                            <div
                                className={`${
                                    user?.id == val.from_id ? 'sntMsgWrpr' : 'rcvdMsgWrpr'
                                } msgOtrWrpr`}
                            >
                                <div className="msgContent">{val.message}</div>
                            </div>
                        ))}
                    </div>
                    <div className="msgInputBoxWrpr">
                        <div className="msgInputBox">
                            <textarea
                                placeholder="Enter Message..."
                                value={messageInput}
                                onChange={handleChange}
                            ></textarea>
                            <IconButton className="sndButton" onClick={handleSubmit}>
                                <span className="material-icons">send</span>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </FormDialog>
            {/* <FullScreenPopup open={messagePopup} handleClose={() => setMessagePopup(false)} /> */}
        </div>
    )
}

export default MessagePlugin
