import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../Product/context/auth/authContext'
import productContext from '../../Product/context/product/productContext'
import axios from 'axios'
import { swapCountry } from '../commonFunctions'
import { useTranslation } from 'react-i18next'
import FacebookLogin from 'react-facebook-login'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
function QuickSignupFunction({
    title,
    subTitle,
    regpro,
    defaultCountryCode,
    termsCondition,
    userType,
    autoComplete,
    need_seller_verify,
}) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { getAlldynamic, dynamicproducts } = useContext(productContext)
    const [fields, setFields] = useState({})
    const { t } = useTranslation()
    let { register, responseStatus, logout, isAuthenticated, freeResellerSubscription } =
        authContext
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [loading, setLoading] = useState(false)
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, t('special_charcters'))
            .max(20, 'Maximum 20 characters')
            .required(t('required')),
        last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, t('special_charcters'))
            .max(20, 'Maximum 20 characters')
            .required(t('required')),
        email: Yup.string()
            .email(t('invalid_email'))
            .max(250, 'Maximum 250 characters')
            .required(t('required')),
        phone: Yup.string()
            .trim()
            .matches(/^\+/, 'Enter country code at the start')
            .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
            .min(12, 'Invalid Number!')
            .max(16, 'Maximum 14 characters')
            .required('Required'),
        password: Yup.string().min(8, 'Minimum 8 characters').required(t('required')),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address1: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        address2: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters'),
        city: Yup.string()
            .trim()
            .matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only Alphabets are allowed')
            .required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(6, 'Maximum 6 characters')
            .min(5, 'Minimum 5 characters')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords not match')
            .required(t('required')),
        termCheck: Yup.boolean().oneOf([true], t('acct_terms')).required(t('acct_terms')),
        // card_profileid: Yup.string().when('role', {
        //     is: '1' || 1,
        //     then: Yup.string().trim().required('Required'),
        // }),
    })

    const requiredfields = {
        role: userType === 'seller' ? 1 : 0,
        first_name: '',
        last_name: '',
        email: '',
        phone: '+1',
        password: '',
        confirmPassword: '',
        country: defaultCountryCode ? defaultCountryCode : '',
        state: '',
        city: '',
        address1: '',
        address2: '',
        zip: '',
        termCheck: 0,
        community: 'auction_io',
        is_auctionio: 1,
        card_profileid: '',
        regtype: userType === 'seller' ? 1 : undefined,
        status:
            global.storeConfigration?.need_reg_verification_email?.value == 1 ||
            global.storeConfigration?.need_admin_verification?.value == 1
                ? 'unverified'
                : '',
        redirectURL:
            global.storeConfigration?.need_reg_verification_email?.value == 1
                ? `${window.location.href.split('#login')[0]}activateaccount`
                : '',
    }

    const formik = useFormik({
        initialValues: fields.dynamic_fields
            ? { ...requiredfields, ...fields.dynamic_fields }
            : requiredfields,
        validationSchema: fields.dynamic_validation ? fields.dynamic_validation : validationArray,
        enableReinitialize: true,
        onSubmit: async (values) => {
            //console.log('valuesss', values)
            setLoading(true)
            if (dynamicproducts.length > 0) {
                if (dynamicproducts[0].form_json.includes('[{')) {
                    var dynamic_data = JSON.parse(dynamicproducts[0].form_json),
                        final_dynamic_data = []
                    dynamic_data.map((va) => {
                        final_dynamic_data.push({ ...va, ...{ user_value: values[va.name] } })
                    })
                    values.dynamic_fields = JSON.stringify(final_dynamic_data)
                }
            }
            if (userType === 'seller') {
                if (need_seller_verify == 1 && values.role == 1) {
                    values.status = 'unverified'
                    values.become_seller_notify = 1
                }
                values.role = 1
                register(values)
            } else {
                register(values)
            }
        },
    })
    const responseFacebook = (response) => {
        const params = {
            first_name: response.name,
            email: response.email,
            fb_id: response.accessToken,
            fb_json: response.id,
            password: response.id,
            community: 'auction_io',
            is_auctionio: 1,
            role: 0,
        }
        register(params)
    }
    const responseGoogle = (response) => {
        const decode = jwt_decode(response.credential)
        console.log('decode signup', decode)
        const params = {
            first_name: decode.name,
            email: decode.email,
            li_id: response,
            li_json: decode.jti,
            password: decode.sub,
            community: 'auction_io',
            is_auctionio: 1,
            role: 0,
        }
        register(params)
    }
    let data = []
    /*eslint-disable*/
    // if (multipleUserRole) {
    // data.push({
    //     title: '',
    //     type: 'radio',
    //     noLabel: true,
    //     name: 'role',
    //     int: 1,
    //     class: 'col-12 mb-3',
    //     item: [
    //         { id: 0, description: 'Buyer' },
    //         { id: 1, description: 'Seller' },
    //     ],
    //     formik: formik,
    // })
    // }

    data.push(
        {
            label: t('first_name') + '*',
            name: 'first_name',
            type: 'text',
            placeholder: t('enter_first'),
            class: 'col-12 col-sm-6',
            autoFocus: true,
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: t('last_name') + '*',
            name: 'last_name',
            type: 'text',
            placeholder: t('enter_last'),
            class: 'col-12 col-sm-6',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: t('email_address') + '*',
            name: 'email',
            type: 'email',
            placeholder: t('enter_email'),
            class: 'col-12',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: t('mobile_number') + '*',
            name: 'phone',
            type: 'text',
            placeholder: t('enter_mobile'),
            class: 'col-12',
            countryCodeEditable: true,
            enableLongNumbers: 1,
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: t('password') + '*',
            name: 'password',
            type: 'password',
            placeholder: t('enter_password'),
            class: 'col-12',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: t('confirm_password') + '*',
            name: 'confirmPassword',
            type: 'password',
            placeholder: t('reenter_password'),
            class: 'col-12',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: 'Address Line 1' + '*',
            name: 'address1',
            type: 'text',
            placeholder: 'Enter your address line 1',
            class: 'col-12 col-sm-6',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            placeholder: 'Enter your address line 2',
            class: 'col-12 col-sm-6',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City' + '*',
            name: 'city',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-12 col-sm-6',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            label: 'Zip code' + '*',
            placeholder: 'Enter your zip code',
            class: 'col-12 col-sm-6',
            type: 'text',
            name: 'zip',
            autoComplete: autoComplete ? 1 : 0,
        },
        {
            title: '',
            type: 'radio',
            noLabel: true,
            name: 'regtype',
            int: 1,
            class:
                global.storeConfigration?.include_reg_type?.value == 1 && userType === 'seller'
                    ? 'col-md-12'
                    : 'd-none',
            item: [
                { id: 1, description: 'Reseller' },
                { id: 2, description: 'Artist' },
                { id: 3, description: 'Gallery' },
            ],
            formik: formik,
        },
        {
            label: [
                t('agree_terms'),

                termsCondition ? (
                    <a target="_blank" rel="noreferrer" href={termsCondition} key="termsOfSerice">
                        terms and conditions
                    </a>
                ) : (
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        to={'/termsOfService'}
                        key="termsOfSerice"
                    >
                        {t('terms_conditions')}
                    </Link>
                ),
            ],
            name: 'termCheck',
            type: 'checkbox',
            class: 'col-12',
        },
    )

    const registerInfo = {
        formik: formik,
        data,
    }

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    if (
                        global.pluginConfiguration?.reseller_subscription?.enable == 1 &&
                        userType === 'seller'
                    ) {
                        freeResellerSubscription({
                            user_id: responseStatus.data?.userid,
                            sub_status: 1,
                            plan_name: 'vip',
                            plan_commision: 20,
                        })
                    } else {
                        handleRedirectInternal(history, 'login')
                    }
                }
                setLoading(false)
            }
            if (responseStatus.from === 'freeResellerSubscription') {
                handleRedirectInternal(history, 'login')
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (userType != undefined) {
            formik.setFieldValue('regtype', 1)
        }
    }, [userType])

    useEffect(() => {
        if (regpro && isAuthenticated) {
            var role = regpro.location.search.split('=')[1]
            formik.values.role = role
        }
        if (isAuthenticated) {
            logout()
        }
    }, [regpro])

    useEffect(() => {
        getAlldynamic({
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
            store_id: global?.storeDetails?.id ? global.storeDetails.id : '',
            type: 3,
        })
    }, [])

    useEffect(() => {
        if (formik) {
            if (dynamicproducts.length > 0) {
                if (dynamicproducts[0].form_json.includes('[{')) {
                    var data_add = data.slice(0, data.length - 2)
                    var json_temp = JSON.parse(dynamicproducts[0].form_json_temp)
                    var dynamic_fields = {}
                    JSON.parse(dynamicproducts[0].form_json).map((val, ind) => {
                        if (val.type == 'radio') {
                            // formik.values[val.name] = val.values.filter(
                            //     (v) => v.selected == 'true',
                            // )[0].value
                            var dynamic_selected_value = val.values.filter(
                                (v) => v.selected == 'true',
                            )
                            if (dynamic_selected_value.length > 0) {
                                dynamic_fields[val.name] = dynamic_selected_value[0].value
                            }
                        } else if (val.type == 'checkboxgroup') {
                            dynamic_fields[val.name] = []
                            val.values.map((kt) => {
                                if (kt.selected == 'true') {
                                    dynamic_fields[val.name].push(kt.value)
                                }
                            })
                        } else {
                            dynamic_fields[val.name] = json_temp[ind].value
                                ? json_temp[ind].value
                                : ''
                        }
                        data_add.push(val)
                    })
                    var dynamic_validation = Yup.object().shape(
                        json_temp.reduce((a, c) => {
                            let fieldSchema

                            if (c.required == 'true') {
                                if (c.type == 'text') {
                                    fieldSchema = Yup.string()
                                        .trim()
                                        .matches(
                                            /^[a-zA-Z\s]*$/g,
                                            'The special characters and numbers are not allowed!',
                                        )
                                        .max(
                                            parseInt(c.maxlength),
                                            `Maximum ${c.maxlength} characters`,
                                        )
                                        .required('Required')
                                }
                                if (c.type == 'textarea') {
                                    fieldSchema = Yup.string()
                                        .trim()
                                        .max(
                                            parseInt(c.maxlength),
                                            `Maximum ${c.maxlength} characters`,
                                        )
                                        .required('Required')
                                }
                                if (c.type == 'number') {
                                    fieldSchema = Yup.number()
                                        .typeError('Only numbers are allowed')
                                        .min(parseInt(c.min), `Minimum value is ${c.min}.`)
                                        .max(parseInt(c.max), `Maximum value is ${c.max}.`)
                                        .required('Required')
                                }
                                if (c.type == 'select') {
                                    fieldSchema = Yup.string().trim().required('Required')
                                }
                                if (c.type == 'checkbox-group') {
                                    fieldSchema = Yup.array()
                                        .min(1, 'Please select atleast one checkbox')
                                        .of(
                                            Yup.string().required(
                                                'Please select atleast one checkbox',
                                            ),
                                        )
                                        .required('Please select atleast one checkbox')
                                }
                                return { ...a, [c.name]: fieldSchema }
                            } else {
                                return a
                            }
                        }, {}),
                    )
                    setFields({
                        data: [...data_add, ...data.slice(data.length - 2)],
                        formik: formik,
                        dynamic_validation: Yup.object().shape({
                            ...validationArray.fields,
                            ...dynamic_validation.fields,
                        }),
                        dynamic_fields: dynamic_fields,
                    })
                    data = [...data_add, ...data.slice(data.length - 2)]
                } else {
                    setFields({ data: data, formik: formik })
                }
            } else {
                setFields({ formik: formik, data: data })
            }
        }
    }, [dynamicproducts, stateList, countryList])

    return (
        <form className="reg-step1" onSubmit={formik.handleSubmit}>
            {title && <h1>{title}</h1>}
            {subTitle && <h4>{subTitle}</h4>}
            <div className="row">
                {Object.keys(fields).length > 0
                    ? mapData({ formik: formik, data: fields.data })
                    : mapData(registerInfo)}
            </div>
            <div className="text-center form-group">
                <PrimaryButton disabled={loading} label={t('submit')} type="submit" />
                {global.pluginConfiguration?.login_with_facebook?.enable == 1 ? (
                    <div className="mt-3">
                        <FacebookLogin
                            appId={global.pluginConfiguration?.login_with_facebook?.client_key}
                            fields="name,email,picture"
                            // onClick={componentClicked}
                            callback={responseFacebook}
                            textButton="Signup With Facebook"
                            size="medium"
                        />
                    </div>
                ) : null}
                {global.pluginConfiguration?.login_with_google?.enable == 1 ? (
                    <GoogleOAuthProvider
                        clientId={global.pluginConfiguration?.login_with_google?.client_key}
                    >
                        <div className="mt-3 googleLogin">
                            <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                    responseGoogle(credentialResponse)
                                }}
                                shape="rectangular"
                                size="large"
                                width={'540'}
                                onError={() => {
                                    console.log('Login Failed')
                                }}
                                text="signup_with"
                            />
                        </div>
                    </GoogleOAuthProvider>
                ) : null}
                <div className="newAcc mt-3 text-center">
                    <p>
                        Already have an account ?{' '}
                        <Link
                            to="/login"
                            className={`${global.storeDetails.id === 41 && 'full-basket-login'}`}
                        >
                            Login here
                        </Link>
                    </p>
                </div>
            </div>
        </form>
    )
}

export default QuickSignupFunction
