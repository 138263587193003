import React, { useState, useEffect } from 'react'
import VCModal from '../Modal'
import { getTimeInterval } from '../../utils/commonFunctions'
import { getBiddingHistory } from '../../Services/Bidding.service'
import Nodata from '../NoData/nodata'
import moment from 'moment-timezone'
import Popup from '../../Product/components/organisms/Popup'
import { socket } from '../../../src/Product/common/socket'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '../../Product/common/components'
const Bidhistory = (props) => {
    const [bidHistory, setBidHistory] = useState([])
    const [isloading, setLoading] = useState(false)
    const [bidhistorytime, setBidHistorytime] = useState('')
    const { t } = useTranslation()
    useEffect(() => {
        const getHistory = async () => {
            setLoading(true)
            let responce = await getBiddingHistory(props.value.product_id)
            try {
                if (
                    responce.data &&
                    responce.data.success === 'yes' &&
                    Array.isArray(responce.data.results)
                ) {
                    setBidHistory([...responce.data.results])
                }
            } catch (err) {
                // console.log(err)
            } finally {
                setLoading(false)
            }
        }
        props.value.product_id && getHistory()
        // console.log('value===>', props.value)
    }, [props.value])

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    var serverTime = new Date(data.dTime)
                    setBidHistorytime(getTimeInterval(props.value.end || '', serverTime))
                }
            })
        }
    }, [props.value])
    console.log(props, 'classNameCheck')

    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle={t('bid_history')}
        >
            <>
                {/* <div className="time-left">
                    Time left: <img src="/image/pv-timer.svg" alt="" />
                    {bidhistorytime ? bidhistorytime : ''}
                </div> */}
                {!isloading && bidHistory.length !== 0 ? (
                    <table className="table table-hover bidHistory">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                {global.storeConfigration?.hide_bidder?.value != '1' && (
                                    <th>{t('username')}</th>
                                )}
                                {global.storeConfigration?.hide_bidder_id?.value != '1' && (
                                    <th>{t('bidderid')}</th>
                                )}
                                <th>{t('date') + ' & ' + t('time')}</th>
                                <th>{t('amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bidHistory.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="clearfix responsiveView" data-title="S.No">
                                            {index + 1}
                                        </td>
                                        {global.storeConfigration?.hide_bidder?.value != '1' && (
                                            <td
                                                className="clearfix responsiveView"
                                                data-title="username"
                                            >
                                                {global.storeConfigration?.full_name_username
                                                    ?.value == 1
                                                    ? item.username
                                                    : item.namesec}
                                            </td>
                                        )}
                                        {global.storeConfigration?.hide_bidder_id?.value != '1' && (
                                            <td
                                                className="clearfix responsiveView"
                                                data-title="Bidder Id"
                                            >
                                                {item.bidder_id_vc
                                                    ? item.bidder_id_vc
                                                    : item.user_id}{' '}
                                                {item.is_auctionio ? '(Auction Io)' : ''}
                                            </td>
                                        )}
                                        <td
                                            className="clearfix responsiveView"
                                            data-title="Date & Time"
                                        >
                                            {item.created_at
                                                ? `${moment(item.created_at).format(
                                                      'MMM Do YYYY, h:mm a',
                                                  )} `
                                                : '-'}
                                        </td>
                                        <td
                                            className="clearfix responsiveView nowrap text-center"
                                            data-title="Amount"
                                        >
                                            {currencyFormat(
                                                parseFloat(item.realproposed_amount).toFixed(2),
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : !isloading && bidHistory.length === 0 ? (
                    <Nodata />
                ) : (
                    <></>
                )}
            </>
        </Popup>
    )
}

export default Bidhistory
