import React, { useEffect, useState, useContext } from 'react'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import './headernav.css'
import { set } from 'lodash'
const Headernavmenu = (props) => {
    const [anchormenu, setanchormenu] = useState({ anchorEl: null, popno: null })
    const [display_submenu, setSubmenu] = useState(true)
    const [indexVal, setIndexVal] = useState()
    const handleMenu = (event, popno) => {
        setanchormenu({ anchorEl: event.currentTarget, popno: popno })
        setIndexVal(popno)
        console.log(event, popno, 'popno')
    }
    const handleMenu1 = (event) => {
        //setanchormenu(event.currentTarget)
        setSubmenu(true)
    }

    const handleClose = () => {
        setanchormenu({ anchorEl: null, popno: null })
        setSubmenu(false)
    }
    return (
        <>
            {global.storeDetails?.header_menu ? (
                props.type == 'desk' ? (
                    <>
                        {global.storeDetails?.header_menu.length > 0
                            ? global.storeDetails.header_menu.map((val, index) =>
                                  val.subLinks.length > 0 ? (
                                      <li className="mr-3">
                                          <div className="nav-menu-from-store">
                                              {val.path ? (
                                                  <a
                                                      //   target={'_blank'}
                                                      //   rel="noreferrer"
                                                      // activeClassName="active"
                                                      //onClick={(event) => handleMenu(event, index)}
                                                      href={val.path}
                                                  >
                                                      {val.label}
                                                  </a>
                                              ) : (
                                                  <Button
                                                      aria-controls={`simple-menu_${index}`}
                                                      aria-haspopup="true"
                                                      onClick={(event) => handleMenu(event, 1)}
                                                  >
                                                      {val.label}
                                                  </Button>
                                              )}

                                              <Button
                                                  aria-controls={`simple-menu_${index}`}
                                                  aria-haspopup="true"
                                                  onClick={(event) => handleMenu(event, 2)}
                                              >
                                                  {Boolean(anchormenu.anchorEl) &&
                                                  index === anchormenu.popno ? (
                                                      <span className="material-icons-outlined">
                                                          expand_less
                                                      </span>
                                                  ) : (
                                                      <span className="material-icons-outlined">
                                                          expand_more
                                                      </span>
                                                  )}
                                              </Button>
                                          </div>
                                          <Popover
                                              id={`simple-menu_${indexVal}`}
                                              open={
                                                  Boolean(anchormenu.anchorEl) &&
                                                  indexVal === anchormenu.popno
                                              }
                                              anchorEl={anchormenu.anchorEl}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'right',
                                              }}
                                              transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right',
                                              }}
                                          >
                                              {indexVal === anchormenu.popno && (
                                                  <ul className="headerDropMenu">
                                                      {val.subLinks.map((data) => (
                                                          <a
                                                              rel="noreferrer"
                                                              //target={'_blank'}
                                                              // activeClassName="active"
                                                              href={data.path}
                                                          >
                                                              <ListItem>{data.label}</ListItem>
                                                          </a>
                                                      ))}
                                                  </ul>
                                              )}
                                          </Popover>
                                      </li>
                                  ) : (
                                      <li className="mr-3">
                                          <a
                                              //   target={'_blank'}
                                              //   rel="noreferrer"
                                              // activeClassName="active"
                                              href={val.path}
                                          >
                                              {val.label}
                                          </a>
                                      </li>
                                  ),
                              )
                            : ''}
                    </>
                ) : props.type == 'auctioneer' ? (
                    <>
                        {global.storeDetails?.header_menu.length > 0
                            ? global.storeDetails.header_menu.map((val, index) =>
                                  val.subLinks.length > 0 ? (
                                      <li className="mr-3">
                                          <div className="nav-menu-from-store">
                                              <a
                                                  //   target={'_blank'}
                                                  // activeClassName="active"
                                                  href={val.path}
                                                  //rel="noreferrer"
                                              >
                                                  <Button className="headerTopBtn signup">
                                                      {val.label}
                                                  </Button>
                                              </a>

                                              <Button
                                                  className="headerTopBtn signup"
                                                  aria-controls={`simple-menu_${index}`}
                                                  aria-haspopup="true"
                                                  onClick={(event) => handleMenu(event, index)}
                                              >
                                                  {Boolean(anchormenu.anchorEl) &&
                                                  index === anchormenu.popno ? (
                                                      <span className="material-icons-outlined">
                                                          expand_less
                                                      </span>
                                                  ) : (
                                                      <span className="material-icons-outlined">
                                                          expand_more
                                                      </span>
                                                  )}
                                              </Button>
                                          </div>
                                          <Popover
                                              id={`simple-menu_${index}`}
                                              open={
                                                  Boolean(anchormenu.anchorEl) &&
                                                  index === anchormenu.popno
                                              }
                                              anchorEl={anchormenu.anchorEl}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'right',
                                              }}
                                              transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right',
                                              }}
                                          >
                                              {index === anchormenu.popno && (
                                                  <ul className="headerDropMenu">
                                                      {val.subLinks.map((data) => (
                                                          <a
                                                              rel="noreferrer"
                                                              //target={'_blank'}
                                                              // activeClassName="active"
                                                              href={data.path}
                                                          >
                                                              <ListItem>{data.label}</ListItem>
                                                          </a>
                                                      ))}
                                                  </ul>
                                              )}
                                          </Popover>
                                      </li>
                                  ) : (
                                      <li className="mr-2">
                                          <a
                                              //   rel="noreferrer"
                                              //   target={'_blank'}
                                              // activeClassName="active"
                                              href={val.path}
                                          >
                                              <Button className="headerTopBtn signup">
                                                  {val.label}
                                              </Button>
                                          </a>
                                      </li>
                                  ),
                              )
                            : ''}{' '}
                    </>
                ) : global.storeDetails?.header_menu.length > 0 ? (
                    global.storeDetails.header_menu.map((val) => (
                        <ListItem button>
                            {val.subLinks.length > 0 ? (
                                <li className="mr-3">
                                    <div className="nav-menu-from-store d-flex align-items-center">
                                        <a
                                            //target={'_blank'}
                                            rel="noreferrer"
                                            // activeClassName="active"
                                            href={val.path}
                                        >
                                            {val.label}
                                        </a>

                                        <Button
                                        // aria-controls="simple-menu"
                                        // aria-haspopup="true"
                                        //onClick={handleMenu1}
                                        >
                                            {!display_submenu ? (
                                                <span className="material-icons-outlined">
                                                    expand_more
                                                </span>
                                            ) : (
                                                <span className="material-icons-outlined">
                                                    expand_less
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                    {/* <Popover
                                        id={'simple-menu'}
                                        open={Boolean(anchormenu)}
                                        anchorEl={anchormenu}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    > */}
                                    {display_submenu ? (
                                        <ul className="headerDropMenu">
                                            {val.subLinks.map((data) => (
                                                <a
                                                    rel="noreferrer"
                                                    //target={'_blank'}
                                                    // activeClassName="active"
                                                    href={data.path}
                                                >
                                                    <ListItem>{data.label}</ListItem>
                                                </a>
                                            ))}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                    {/* </Popover> */}
                                </li>
                            ) : (
                                <a
                                    // activeClassName="active"
                                    href={val.path}
                                    // rel="noreferrer"
                                    // target={'_blank'}
                                >
                                    <span className="material-icons">description</span>
                                    {val.label}
                                </a>
                            )}
                        </ListItem>
                    ))
                ) : (
                    ''
                )
            ) : (
                ''
            )}
        </>
    )
}
export default React.memo(Headernavmenu)
