export const SUBSCRIPTION = 'SUBSCRIPTION'
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES'
export const GET_ALL_REFERRALS = 'GET_ALL_REFERRALS'
export const GET_FEEDBACK_FIELDS = 'GET_FEEDBACK_FIELDS'
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK'
export const GET_PRO_FEEDBACK = 'GET_PRO_FEEDBACK'
export const GET_ALL_BANKS = 'GET_ALL_BANKS'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
