import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import AuthContext from '../../Product/context/auth/authContext'
import Spinner from '../../Component/Spinner'
import { useTranslation } from 'react-i18next'
import FacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'
import cartContext from '../../Product/context/cart/cartContext'
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { Button } from '@material-ui/core'

function LoginFunction({ title, subTitle, after_login_redirect_path, autoComplete }) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { getUserCart } = useContext(cartContext)
    const { login_email } = useParams()
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    let searchParams = new URLSearchParams(window.location.search)
    let { login, responseStatus } = authContext

    let [passwordShown, setPasswordShown] = useState(false)
    const [isPos, setIsPos] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        username: Yup.string()
            .email(t('invalid_email'))
            .required(t('required') + '!'),
        password: Yup.string().required(t('required') + '!'),
    })

    useEffect(() => {
        //console.log('login_email', login_email)
        if (
            window.location.pathname.includes('/autologin') ||
            window.location.pathname.includes('/auto-login')
        ) {
            setLoad(true)
            let username = login_email
            let password = '12345678'
            let autologin = 1
            let encrypt = window.location.pathname.includes('/auto-login') ? 1 : 0
            setIsPos(window.location.pathname.includes('/auto-login-pos'))
            let loginValues = {
                username: username,
                password: password,
                autologin: autologin,
                encrypt: encrypt,
            }
            //console.log('loginvalues', loginValues)
            login(loginValues)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            username: localStorage.username ? localStorage.username : '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
            community: 'auction_io',
            is_auctionio: 1,
            account_id:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? process.env.REACT_APP_AUCTIONPAYID
                    : undefined,
            description:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? 'Login Customer Creation'
                    : undefined,
            register_to_payment_gateway:
                global.storeConfigration?.enable_payment_gateway_register?.value,
            login_with_suspend: global.storeConfigration?.suspended_user_access?.value
                ? global.storeConfigration.suspended_user_access.value
                : 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoading(true)
            if (values.remember_me) {
                localStorage.username = values.username
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.username
                delete localStorage.password
                delete localStorage.remember_me
            }
            login(values)
        },
    })
    const responseFacebook = (response) => {
        const params = {
            username: response.email,
            password: response.id,
            community: 'auction_io',
            is_auctionio: 1,
        }
        login(params)
    }
    console.log(
        jwt_decode(
            'eyJhbGciOiJSUzI1NiIsImtpZCI6IjBlNzJkYTFkZjUwMWNhNmY3NTZiZjEwM2ZkN2M3MjAyOTQ3NzI1MDYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI1NjM0MzkwNDYzMzgta3MyNmNtbzdqNGtmNjU1YThscjAwNHMxY3NicHVldnAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI1NjM0MzkwNDYzMzgta3MyNmNtbzdqNGtmNjU1YThscjAwNHMxY3NicHVldnAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDkxOTg1NzM5NDQ0NjU2NzE2NTUiLCJlbWFpbCI6InNhYW5tdWdha3VtYWFyLmF1Y3Rpb25zb2Z0d2FyZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzAwODI3Mzg4LCJuYW1lIjoiU2Fhbm11IFNrIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pXdS1DYzZNQkRHV25adWNEWEpoTFJlUnFrV29OcnFLNVQwX3ZzWU9COT1zOTYtYyIsImdpdmVuX25hbWUiOiJTYWFubXUiLCJmYW1pbHlfbmFtZSI6IlNrIiwibG9jYWxlIjoiZW4tR0IiLCJpYXQiOjE3MDA4Mjc2ODgsImV4cCI6MTcwMDgzMTI4OCwianRpIjoiYWQxN2M1YTg3N2M4NDYyMWE2OTQzY2I4YjNlN2JlODZhZjcxZjk5NSJ9.WK2qbvDJRlzpdfuQwf9gReUFkzyaUYPTnmCYDl9MKtlDzWPG0fgjgV7XGKguhRIZNWBdI-XdqDKAnDn-ywTbeNdbfaup7R75Ec5VhH3vfotGGhgBuvGjPn05seYfOhwPCuehk1hXXYokt0IxkzFm4W68UkpxGsEsCQ7D3nXQJsEMAXJqLBQelHhrYj7qxHL3lBq1-DeYo5-hkL5rKLkMFqrdBvvHpC_KIcnIOdkx_NcSV93Nl0rutKFzRSmcL6RYogb1eMrIuMm4kPWETpmopkcFpbDjY6laouAiAqCY0wEQD17wHNfFamlGzoT4yfask98nTC5uV9NQKJGHs7VFXA',
        ),
    )
    const responseGoogle = (response) => {
        console.log(response, 'checkResponse')
        const decode = jwt_decode(response.credential)
        const params = {
            username: decode.email,
            password: decode.sub,
            community: 'auction_io',
            is_auctionio: 1,
        }
        login(params)
    }
    const rememberMe = {
        formik: formik,
        data: [
            {
                label: t('rember_me'),
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'Remember me',
                class: 'w-100',
                formik: formik,
            },
        ],
    }

    const loginInfo = {
        formik: formik,
        data: [
            {
                label: t('email'),
                name: 'username',
                type: 'email',
                placeholder: t('enter_email'),
                class: 'col-12',
                autoFocus: true,
                autoComplete: autoComplete ? 1 : 0,
            },
            {
                label: t('password'),
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                placeholder: t('enter_password'),
                class: 'col-12',
                autoComplete: autoComplete ? 1 : 0,
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }

    // const googleLogin = global?.pluginConfiguration?.login_with_google?.client_key
    //     ? useGoogleLogin({
    //           onSuccess: (credentialResponse) => responseGoogle(credentialResponse),
    //       })
    //     : null

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(
                        history,
                        after_login_redirect_path
                            ? after_login_redirect_path
                            : global.storeConfigration?.redirect_login?.value
                            ? global.storeConfigration.redirect_login.value
                            : searchParams.get('auction_id')
                            ? 'search/product-buyer-auction/' +
                              searchParams.get('auction_id') +
                              '?id_new=' +
                              searchParams.get('auction_id')
                            : isPos && global.pluginConfiguration?.pos?.enable
                            ? 'dashboard/pos'
                            : 'search',
                    )
                    getUserCart()
                    setLoad(false)
                }
                setLoading(false)
            }
        }
    }, [responseStatus])
    return (
        <>
            {load && <Spinner />}
            <form className="login-ip-box" onSubmit={formik.handleSubmit}>
                <h1>{title ? title : 'Sign In'}</h1>
                {subTitle && <h4>{subTitle}</h4>}
                <div className="row">{mapData(loginInfo)}</div>
                <div className="d-flex justify-content-between align-items-center fpRm mb-2">
                    {mapData(rememberMe)}
                    <div className="w-100 text-right forgottext">
                        <Link to="/forgot_password">{t('forgot_password')}?</Link>
                    </div>
                </div>
                <div className="text-center form-group">
                    <PrimaryButton disabled={loading} label={t('login')} type="submit" />
                    {global.pluginConfiguration?.login_with_facebook?.enable == 1 ? (
                        <div className="mt-3">
                            <FacebookLogin
                                appId={global.pluginConfiguration?.login_with_facebook?.client_key}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                size="medium"
                            />
                        </div>
                    ) : null}
                    {global.pluginConfiguration?.login_with_google?.enable == 1 &&
                    global?.pluginConfiguration?.login_with_google?.client_key ? (
                        <div className="mt-3 googleLogin">
                            {/* <Button className="googleLoginBtn" onClick={() => googleLogin()}>
                                <img src="/assets/images/google.png" />
                                Sign in with Google
                            </Button> */}

                            <GoogleOAuthProvider
                                clientId={
                                    global?.pluginConfiguration?.login_with_google?.client_key || ''
                                }
                            >
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                        responseGoogle(credentialResponse)
                                    }}
                                    shape="rectangular"
                                    size="large"
                                    width={'450'}
                                    onError={() => {
                                        console.log('Signup Failed')
                                    }}
                                    text="signin_with"
                                />
                            </GoogleOAuthProvider>
                        </div>
                    ) : null}
                    <div className="newAcc mt-3 text-center">
                        <p>
                            Don&apos;t have an account? <Link to="/sign-up">Create Account</Link>
                        </p>
                    </div>
                </div>
            </form>
        </>
    )
}

export default LoginFunction
