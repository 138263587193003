import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AlertContext from '../../Product/context/alert/alertContext'
import UserContext from '../../Product/context/user/userContext'
import CheckBox from '../../Product/components/atoms/CheckBox'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal } from '../../Product/common/components'
import AuthContext from '../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import UserActivity from './UserActivity'

const PreferenceForm = (props) => {
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)

    const { user, isAuthenticated } = authContext

    const { setAlert } = alertContext
    const { updatepreference, responseStatus, getpreference, preference, clearResponse } =
        userContext
    const history = useHistory()
    const [reload, setReload] = useState(false)
    const { t } = useTranslation()
    const formikPreference = useFormik({
        initialValues: {
            email_settings: [],
            sms_settings: [],
            notify_id: 0,
        },
        onSubmit: (values) => {
            let sendValue = values
            sendValue.email_settings = sendValue.email_settings.toString(',')
            sendValue.sms_settings = sendValue.sms_settings.toString(',')
            updatepreference(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'updatepreference') {
                    getpreference()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            getpreference()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (preference.length > 0) {
            //console.log(preference, formikPreference.values, 'this is formik values')
            formikPreference.values.email_settings =
                preference[0].email && preference[0].email.split(',').length
                    ? preference[0].email.split(',')
                    : []
            formikPreference.values.sms_settings =
                preference[0].sms && preference[0].sms.split(',').length
                    ? preference[0].sms.split(',')
                    : []
            formikPreference.values.notify_id = preference[0].id
            setReload(!reload)
        }
    }, [preference])

    let allNotifications = [
        { id: '1', description: t('Item_added_watchl') },
        { id: '2', description: t('bid_an_item') },
        { id: '3', description: t('outbid_item') },
        { id: '4', description: t('won_item') },
        //{ id: '5', description: 'Saved searches' },
    ]
    return (
        <div className="preferences-form-wrapper">
            <UserActivity page="preference_page" />
            {props.notifictionpara && <p className="notificationPara">{t('notification_long')}</p>}
            {/* <EditUser /> */}
            <form onSubmit={formikPreference.handleSubmit} autoComplete="nofill" className="mb-3">
                <table className="table text-left">
                    <thead>
                        <tr>
                            <th scope="col">{t('notification')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('sms')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allNotifications.map((data, index) => (
                            <>
                                <tr key={data.id}>
                                    <td>{data.description}</td>
                                    <td>
                                        <CheckBox
                                            name={'email_settings'}
                                            label={''}
                                            checked={
                                                formikPreference.values['email_settings'].indexOf(
                                                    data.id.toString(),
                                                ) !== -1
                                                    ? true
                                                    : false
                                            }
                                            value={data.id.toString()}
                                            onChange={formikPreference.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <CheckBox
                                            name={'sms_settings'}
                                            label={''}
                                            checked={
                                                formikPreference.values['sms_settings'].indexOf(
                                                    data.id.toString(),
                                                ) !== -1
                                                    ? true
                                                    : false
                                            }
                                            value={data.id.toString()}
                                            onChange={formikPreference.handleChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
                <PrimaryButton type="submit" label={t('save')} />
            </form>
        </div>
    )
}

export default PreferenceForm
