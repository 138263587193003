export const english_text = []
english_text.push({
    artist: 'Artist',
    available: 'AVAILABLE',
    artwork: 'ARTWORK',
    Artist_Bio_Page: 'Artist Bio Page',
    add_save_search: 'Add a new saved search',
    apply_code: 'Apply Code',
    action: 'Action',
    about_us: 'About Us',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    add_card: 'ADD A NEW CARD',
    amount: 'Amount',
    about_me: 'About me',
    buyer: 'Buyer',
    back: 'Back',
    bids_won: 'Bids Won',
    bids_active: 'Bids Active',
    bids_lost: 'Bids Lost',
    bio_page_view: 'View Your Bio Page',
    Buyer_Pays_Shippi: 'Buyer Pays Shipping Costs',
    Bio_Page_Link: 'Bio Page Link to Auction Site',
    back_to_dashboard: 'BACK TO DASHBOARD',
    current_lot: 'Current Lots',
    contact_us: 'Contact Us',
    copy_item: 'Copy Item',
    carrier_name: 'Carrier Name',
    current_password: 'Current password',
    country: 'Country',
    city: 'City',
    cards: 'Cards',
    confirm_password: 'Confirm password',
    new_password: 'New password',
    change_password: 'Change password',
    change_status: 'Change Status',
    calculate_shippin: 'Calculate Shipping',
    Control_Timing_Cl: 'Control Timing Clock',
    created_on: 'Created on',
    commission_upon_s: 'commission upon sale',
    dashboard: 'Dashboard',
    date_closed: 'Date Closed',
    date_added: 'Date Added',
    download_inv: 'DOWNLOAD INVOICE',
    diamount_premium: 'diamond premium',
    date: 'Date',
    delte: 'Delete',
    email: 'Email',
    email_address: 'Email address',
    enter_coupon_code: 'Enter Coupon Code',
    edit: 'EDIT',
    faq: 'FAQs',
    favourites: 'Favorites',
    first_name: 'First name',
    from: 'From',
    gallery: 'Gallery',
    Global_Exposure: 'Global Exposure',
    grand_total: 'Grand total',
    home: 'Home',
    Invoice: 'Invoice',
    invoice_Id: 'Invoice Id',
    Invoice_status: 'Invoice status',
    Id: 'Id',
    Image: 'Image',
    inventory_managem: 'Inventory Management',
    Item: 'Item',
    my_bid: 'My Bids',
    my_fav: 'My Favorites',
    my_lots: 'My Lots',
    messages: 'Messages',
    my_account: 'My Account',
    my_profile: 'MY PROFILE',
    my_saved_search: 'My Saved Searches',
    messages: 'Messages',
    mobile_number: 'Mobile number',
    make_payment_link: 'Congratulations! You have won an auction.Click here to make the payment.',
    notifi_preference: 'Notification Preference',
    notification: 'notification',
    No_Records: 'No Records Found!',
    notification_long: `${
        global?.storeDetails?.name ?? ''
    } Will send emails based on a wide variety of events that
    may occur as seen below. Some notifications such as Payment information will be
    sent regardless of your email preference settings.`,
    Newsletter_To_Cli: 'Newsletter to Clients',
    login: 'Login',
    lot_id: 'Lot Id',
    listings: 'Listings',
    load_more: 'LOAD MORE',
    lot: 'Lot',
    last_name: 'Last name',
    register: 'Register',
    reseller: 'Reseller',
    sellers: 'Sellers',
    submit: 'Submit',
    saved_search: 'Saved Search',
    subscribe: 'Subscribe',
    subscribed: 'Subscribed',
    save: 'Save',
    search: 'Search',
    showing: 'Showing',
    state: 'State',
    s_no: 'S:No',
    paypal_pay: 'Paypal pay',
    status: 'Status',
    ship_details: 'ShipStation Ship UPS, FedEx Internationally',
    shipping_label: 'Shipping Label',
    Stripe_Payment_Se: 'Stripe Payment Services',
    Service_And_Help_: 'Service and Help Desk',
    sms: 'SMS',
    site_launch: `${global?.storeDetails?.name}.All rights reserved.`,
    saved_cards: 'Saved Cards',
    stripe_info: 'Stripe Account Info',
    stripe_id: ' You are successfully linked to Stripe Pay. Your account ID is',
    subscription: 'Subscription',
    shipping_status: 'Shipping Status',
    shipping_fee: 'Shipping Fee',
    start_price: 'Start Price',
    sales_tax: 'Sales Tax',
    terms_conditions: 'Terms & Conditions',
    time_left: 'Time left',
    title: 'Title',
    type: 'Type',
    to: 'To',
    tracking_number: 'Tracking Number',
    transaction_detai: 'Transaction Details',
    transaction_Id: 'Transaction Id',
    transaction_fee: 'Transaction Fee',
    type_message_here: 'Type your message here...',
    preferences: 'Preferences',
    preference: 'Preference',
    Privacy_Policy: 'Privacy Policy',
    print_inv: 'PRINT INVOICE',
    Post_Up_To_10_Lot: 'Post up to 10 lots per month',
    password: 'Password',
    phone: 'Phone',
    product_name: 'Product Name',
    price: 'Price',
    payout: 'Payout',
    paid_invoice: 'Paid Invoices',
    post_UNLIMITED_Am: 'Post UNLIMITED amount of work',
    product_details: 'Product Details',
    unpaid_invoice: 'Unpaid Invoices',
    reports: 'Reports',
    report: 'Report',
    recommended_dimen: 'Recommend dimensions',
    report_page_text:
        'Report management gives you direct access of accounting overview based on the reporting you wish to formulate.',
    result_per_page: 'Results per page',
    report_magement: 'REPORT MANAGEMENT',
    upcoming_lot: 'Upcoming Lots',
    upload_banner_ima: 'Upload banner image',
    view: 'View',
    view_invoice: 'View Invoice',
    Zip_code: 'Zip code',
    sign: 'SIGN',
    up: 'UP',
    Earn: 'Earn',
    welcome: 'Welcome',
    i_here_to: "I'm here to",
    buy: 'Buy',
    sell: 'Sell',
    login: 'Login',
    register: 'Register',
    required: 'Required',
    invalid: 'invalid',
    log: 'LOG',
    in: 'IN',
    rember_me: 'Remember Me',
    starts_in: 'Starts In',
    bid_now: 'Bid Now',
    ends_in: 'Ends In',
    est: 'Est',
    condition: 'Condition',
    artist_name: 'Artist Name',
    start_bid: 'Start Bid',
    description: 'Description',
    ship_details: 'Shipping Details',
    current_bid: 'Current Bid',
    or: 'OR',
    place_bid: 'Place Bid',
    place_hard_bid: 'Place Hard Bid',
    username: 'Username',
    bidderid: 'Bidder ID',
    time: 'Time',
    bid_history: 'Bid History',
    view_bid_history: 'View Bid History',
    reports: 'Reports',
    post_single_lot: 'Post Single Lot',
    no_artist_found: 'No artist found',
    no_reseller_found: 'No reseller found',
    no_gallery_found: 'No gallery found',
    similar_reseller: 'Similar Reseller',
    similar_gallery: 'Similar Gallery',
    similar_artwork: 'Similar Artwork',
    home: 'Home',
    active: 'ACTIVE',
    unsold: 'UNSOLD',
    sold: 'SOLD',
    draft: 'DRAFT',
    invoices: 'Invoices',
    name: 'Name',
    winning_bid: 'Winning Bid',
    checkout: 'CHECKOUT',
    add: 'ADD',
    add_card_details: 'Add Card Details',
    card_holder_name: 'Card Holder Name',
    card_number: 'Card Number',
    expiry_month: 'Expiry Month',
    expiry_year: 'Expiry Year',
    cvv: 'CVV',
    select_country: 'Select Country',
    select_state: 'Select State',
    save: 'SAVE',
    timed_auction: 'Timed Auction',
    Item_added_watchl: 'Item added to watchlist',
    bid_an_item: 'You have bid on item',
    outbid_item: 'You were outbid on item',
    won_item: 'You Won An Item',
    agree_terms: 'I agree to the',
    have_account: 'Have an Account',
    acct_terms: 'Please accept Terms and Conditions',
    special_charcters: 'The Special characters and numbers are not allowed',
    invalid_email: 'invalid email format',
    need_account: 'Need An Account',
    signup: 'Signup',
    forgot_password: 'Forgot Password',
    home: 'Home',
    how_it_wors: 'How It Works',
    logout: 'Logout',
    become_a_seller: 'Become a Seller',
    please_click_chec: 'Please Add Unpaid Product And Click Checkout Button',
    post_a_product: 'Post a Product',
    upload_single_ite: 'Upload Single Item',
    lot_title: 'Lot Title',
    category: 'Category',
    sub_category: 'Sub-Category',
    quantity: 'Quantity',
    product_address: 'Product Location Address',
    enable_reserve: 'Enable Reserve',
    auction_start_pri: 'Auction Start Price',
    auction_reserve_p: 'Auction Reserve Price',
    low_est: 'Low EST',
    high_est: 'High EST',
    start_date: 'Start date',
    end_date: 'End date',
    sku: 'SKU',
    shipping: 'Shipping',
    weight: 'Weight',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    pounds: 'Pounds',
    inches: 'Inches',
    sample_images: 'Sample Images',
    upload_photos: 'Upload your photos in the below format',
    fornt_page: 'Front Page Swatch',
    white_background: 'White Background',
    side_image: 'Side Image',
    closeup_image: 'Closeup Image',
    upload: 'Upload',
    preview_video: 'Upload Preview Video',
    recomedation_form: 'Recommended format',
    go_live: 'Go Live',
    save_draft_for_pr: 'save draft for preview',
    must_be_postive: 'Must be postive',
    shoud_be_equal_or_graater: 'Shoud be equal or greater then the starting price',
    buyer_premium: "Buyer's Premium",
    total: 'Total',
    sub_total: 'Sub Total',
    discount_amount: 'Discount Amount',
    payment_details: 'Payment Details',
    save_card: 'Save Card',
    pay: 'Pay',
    card_name: 'Card Name',
    card_address_line: 'Card Address Line',
    card_city: 'Card City',
    card_zIp_code: 'Card Zip Code',
    enter_val_name: 'Enter valid card name',
    enter_val_number: 'Enter valid card number',
    enter_valid_year: 'Enter valid expiry year',
    enter_valid_month: 'Enter valid expiry month',
    enter_valid_cvv: 'Enter valid cvv',
    invalid_year: 'Invalid Year',
    reset: 'Reset',
    password_button: 'Send reset password link',
    back_to_log: 'Back To Login',
    login_to_bid: 'Login To Bid',
    do_not_bid: 'You cannot bid your own product!',
    followers: 'Followers',
    show_more: 'Show More',
    show_less: 'Show Less',
    member: 'Member Since',
    follow: 'Follow',
    unfollow: 'Unfollow',
    tax_certified: 'Upload Tax Exemption Certificate',
    format: 'Format',
    my_b: 'My Bid',
    current_prc: 'Current Price',
    view_lot: 'View Lot',
    Update_Card: 'Update Card Details',
    confirmation: 'Confirmation',
    del_msg: 'Are you sure you want to delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    sav_searches: 'Saved Searches',
    search_txt:
        'You can now save this search into organized  folder in profile Saved Search Letting you manage your resuts more easily.',
    search_term: 'Search Term',
    sav_search: 'Save Search',
    trasactions: 'Transactions',
    won_amount: 'Won Amount',
    repost: 'Repost',
    relist: 'Relist',
    subscribe: 'subscribe',
    card_subscribe: 'Add To Card Subscribe',
    enter_email: 'Enter your email address',
    enter_password: 'Enter your password',
    enter_first: 'Enter your first name',
    enter_last: 'Enter your last name',
    enter_mobile: 'Enter your mobile number',
    reenter_password: 'Re enter your password',
    enter_hest: 'Enter High Est',
    enter_lest: 'Enter Low Est',
    enter_start: 'Enter starting price',
    enter_reserve: 'Enter resereve price',
    enter_city: 'Enter your city',
    enter_zip: 'Enter your zip',
    enter_artist: 'Enter Artist Name',
    enter_lot_title: 'Enter Your Lot Title',
    confirm_bid: 'Confirm bid',
    deposit_amount: 'Deposit Amount',
    subject: 'Subject',
    enter_subject: 'Enter your subject',
    call: 'Call',
    reach_us: 'Reach Us',
    address: 'Address',
    report_profile: 'Report Profile',
    send: 'Send',
    sell_now: 'SELL NOW',
    ship_update_info: 'Rates based on Shipping Address on profile',
    update: 'Update',
    here: 'here',
    shipping_amount: 'Shipping Amount',
    product_id: 'Product Id',
    enter: 'Enter',
    select: 'Select',
    shipping_details: 'Shipping Details',
    payments: 'Payments',
    art_type: 'Art Type',
    Recived: 'Recived',
    should_be_greater_than_equal: 'Should be greater than 1',
    should_greater_than_reserve_price: 'Should be greater than reserveprice',
    should_be_greater_than_start_price: 'Should be greater than the start price',
    should_be_greater_than_the_lowest_price: 'Should be greater than the lowest price',
    lot_sold: 'Lot Sold',
    remove_from_cart: 'Remove from cart',
    buy_now_for: 'Buy now for',
    product: 'Product',
    to_tal: 'TOTAL',
    grand_Total: 'GRAND TOTAL',
    check_out: 'Check Out',
    date_purchased: 'Date Purchased',
    buy_now: 'Buy Now',
    line_item_fee: 'Lot Fee',
    bin_location: 'Bin Location',
    lot_closed: 'Lot Closed',
    add_to_favorites: 'Add to Favorites',
    remove_from_favorites: 'Remove from Favorites',
    share: 'Share',
    enter_bid: 'Enter bid',
    bid: 'Bid',
    or_more: 'or more',
    sort_feed_by: 'Sort Feed by',
    current_price_low_to_high: 'Current price low to high',
    current_price_high_to_low: 'Current price high to low',
    time_remaining: 'Time remaining',
    recently_listed: 'Recently listed',
    search_lots: 'Search lots',
    like: 'Like',
    buy_now_price: 'Buy Now Price',
    tracking_update: 'Seller Will Update The Tracking Number',
    request_tracking: 'Request Tracking Number',
    tracking_number: 'Tracking Number',
    active_lot: 'Active Lots',
    buynow_lot: 'Buy Now Lots',
    purchased_date: 'Purchased Date',
})
