import React, { useState, useEffect, useContext } from 'react'
import Inputfield from '../../../Component/Inputfield/Inputfield'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import './makePayment.css'
import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Popup from '../../../Product/components/organisms/Popup'
import AuthContext from '../../../Product/context/auth/authContext'
import CartContext from '../../../Product/context/cart/cartContext'
import PayContext from '../../../Product/context/payment/payContext'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import {
    currencyFormat,
    handleRedirectInternal,
    jsonToFormData,
    mapData,
} from '../../../Product/common/components'
import UserContext from '../../../Product/context/user/userContext'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AddressBook from '../AddressBook'
import axios from 'axios'
import AddCard from '../StripeIntegration/AddCard'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CurrencyConverterFormat from '../CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import { swapCountry } from '../../commonFunctions'
import DummyPaypal from './DummyPaypal'
import { useFlutterwave, FlutterWaveButton } from 'react-flutterwave'
import CheckBox from '../../../Product/components/atoms/CheckBox'
const MakePaymentComponent = ({
    amount,
    cartCount,
    cart_ids,
    invoice_id,
    type,
    buyers_premium,
    transaction_fee,
    total_payable_amount,
    local_pick_total_payable_amount,
    shipping_fee,
    discount_amount,
    sales_tax,
    local_pick_sales_tax,
    currency,
    redirectUrl,
    items,
    setItems,
    deposit_amount,
    transfer_amount,
    line_item_fee,
    project_id_buynow,
    storeTheme,
}) => {
    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const payContext = useContext(PayContext)
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const {
        static_page,
        getStaticPage,
        getAllShippingAddress,
        all_shipping_address,
        upload_buyer_document,
        responseStatus: responseStatusUser,
    } = userContext
    const { user, loadUser } = authContext
    const { getUserCart } = cartContext
    const {
        successAfterPay,
        successAfterPayAuction,
        successAfterPayupdateforbank,
        successAfterPayAuctionforbank,
        successAfterPayCalculation,
        successAfterPayAuctionCalculation,
        successAfterPaypaypalAuction,
        successAfterPaypaypalBuynow,
        buynow_make_payment,
        buynow_reset_payment,
        clearResponse,
        responseStatus: responseStatusPayment,
    } = payContext

    const { get_all_card_details, getAllStripeCards, createStripeCard } =
        useContext(StripeCardContext)
    const { usercards } = useContext(DirectStripeContext)
    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        addCustomerProfile,
        CraeteBankCustomer,
        BuyerAddBank,
        getBankDetails,
        verifyBankdetails,
        transferamount,
        responseStatus: responseStatusStripeCustomer,
        clearResponse: clearbankpayment,
    } = useContext(StripeCustomerContext)
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saveCard, setSaveCard] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [bank_verify_open, setBankVarify] = useState(false)
    const [addAddressOpen, setAddAddressOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [walletChecked, setWalletChecked] = useState(false)
    const [termschecked, setTermschecked] = useState(false)
    const [popupchecked, setPopupchecked] = useState(false)
    const [termscondtions, setTermscondition] = useState('')
    const [amountNeedTopay, setAmountNeedTopay] = useState(0)
    const [walletSpend, setWalletSpend] = useState(0)
    const [paymentoption, setPaymentoption] = useState(1)
    const [selectAddress, setSelectAddress] = useState(0)
    const [shipping, setShipping] = useState('yes')
    const [uploadDocument, setUploadDocument] = useState(null)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [disablePay, setDisablePay] = useState(
        global.storeConfigration?.unique_slot4?.value ? true : false,
    )
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    let history = useHistory()
    const [cardDetails, setCardDetails] = useState({
        cardName: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
        cardAddress: '',
        cardAddress1: '',
        cardCountry: '',
        cardState: '',
        cardCity: '',
        cardZip: '',
    })
    const [allData, setAllData] = useState([])
    const [move_to_make_an_offer, setMovemake] = useState(false)
    const [documentUploaded, setDocumentUploaded] = useState(false)
    const [allBankData, setAllBankData] = useState([])
    const [cardData, setCardData] = useState('nocard')
    const [error, setError] = useState({})
    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setCardDetails({})
        clearResponse()
        clearbankpayment()
        getUserCart()
        // handleRedirectInternal(
        //     history,
        //     `dashboard/mybids?s=${type === 'buynow' ? 3 : move_to_make_an_offer ? 4 : 1}`,
        // )
        window.location.href = window.location.href.includes(global?.storeDetails?.id)
            ? `/${global?.storeDetails?.id}/dashboard/mybids?s=${
                  type === 'buynow' ? 3 : move_to_make_an_offer ? 4 : 1
              }`
            : `/dashboard/mybids?s=${type === 'buynow' ? 3 : move_to_make_an_offer ? 4 : 1}`
    }
    const params = new URLSearchParams(window.location.search.substring(1))
    const onCheckBoxChange = () => setSaveCard((prevState) => !prevState)
    const onChange = (e) => {
        e.target.checked ? setCardData(e.target.value) : setCardData('nocard')
    }

    useEffect(() => {
        setDocumentUploaded(
            user?.taxfile_org?.includes('{') && JSON.parse(user?.taxfile_org)?.buyer_2,
        )
    }, [user])
    const formikDocument = useFormik({
        initialValues: { buyer_document_2: [] },
    })
    const documentShow = [
        {
            type: 'uploadDropZone',
            formik: formikDocument,
            name: 'buyer_document_2',
            titleText: "Driver's License Upload",
            innerText: (
                <div className="text-center">
                    <span className="material-icons">note_add</span>
                    <br />
                    <p className="mb-2 mt-2">
                        <b>
                            <span>Upload Driver&apos;s License Document</span>
                        </b>
                    </p>
                    <span>Recommended format: .docx, .doc, .pdf, .jpg, .jpeg</span>
                </div>
            ),
            class: `col-12 col-sm-12 `,
            folder: '',
            multiple: true,
            accept: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'application/pdf',
                'image/jpeg',
            ],
            compressImages: false,
        },
    ]
    const handleWalletChange = () => {
        setWalletChecked(!walletChecked)
        var total_pay_amount =
            parseFloat(deposit_amount ? deposit_amount : 0) > 0
                ? deposit_amount
                : shipping_fee != 0
                ? total_payable_amount
                : local_pick_total_payable_amount
        var amount_need_to_pay = parseFloat(total_pay_amount) - parseFloat(user?.wallet_amount)
        var wallet_amount = parseFloat(user?.wallet_amount) - parseFloat(total_pay_amount)
        amount_need_to_pay = amount_need_to_pay <= 0 ? 0 : amount_need_to_pay
        wallet_amount =
            wallet_amount <= 0
                ? user?.wallet_amount
                : parseFloat(user?.wallet_amount) - parseFloat(wallet_amount)
        console.log(amount_need_to_pay, wallet_amount)
        setAmountNeedTopay(amount_need_to_pay)
        setWalletSpend(wallet_amount)
    }

    useEffect(() => {
        if (items?.length > 0) {
            if (items[0].make_an_offer_io == 1) {
                setMovemake(true)
            }
        }
    }, [items])

    useEffect(() => {
        if (!cartCount && user && global.storeConfigration?.stripe_integration?.value != 1) {
            const getData = async () => {
                getAllStripeCards({
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user.card_paymentid,
                    object: 'card',
                })
            }
            getData()
        }
    }, [user, cartCount, history])

    useEffect(() => {
        if (user) {
            getBankDetails({
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
            })
        }
    }, [user])

    useEffect(() => {
        getStaticPage(
            {
                page_id: type === 'buynow' ? 'buynow_success_msg' : 'auction_success_msg',
            },
            1,
        )
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            getStaticPage(
                {
                    page_id: 'checkout_terms_and_condtions',
                },
                1,
            )
        }
    }, [])

    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            if (static_page.content) {
                setTermscondition(static_page.content)
            }
        }
    }, [static_page])

    useEffect(() => {
        if (get_all_card_details) {
            setAllData(get_all_card_details.records)
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (usercards.length) {
            setAllData(usercards)
        }
    }, [usercards])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.from === 'get_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    setAllBankData(
                        responseStatusStripeCustomer.data.data
                            ? responseStatusStripeCustomer.data.data
                            : [],
                    )
                }
            }
            // console.log('++++++++++++++++++++++1', responseStatusStripeCustomer)
            if (responseStatusStripeCustomer.from === 'transfer_amount_buyer') {
                if (
                    responseStatusStripeCustomer.status === 'success' &&
                    responseStatusStripeCustomer.message === 'Payment successfully!'
                ) {
                    if (type === 'buynow') {
                        successAfterPayupdateforbank({
                            cart_ids: JSON.stringify(cart_ids),
                            transfer_id: responseStatusStripeCustomer.data.transfer,
                        })
                    }
                    if (type === 'auction') {
                        successAfterPayAuctionforbank({
                            invoice_id,
                            transid: responseStatusStripeCustomer.data.transfer,
                        })
                    }
                } else {
                    setLoading(false)
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
            }

            if (responseStatusStripeCustomer.from === 'add_bank_customer') {
                if (
                    responseStatusStripeCustomer.status === 'success' ||
                    responseStatusStripeCustomer.message === 'Customer account already created!'
                ) {
                    var bank_data = {
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        user_id: user.id ? user.id : '',
                        account_no: bankformik.values.bank_account_number,
                        rounting_no: bankformik.values.bank_routing_number,
                        holdername: bankformik.values.bank_account_holder_name,
                    }
                    BuyerAddBank(bank_data)
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
            }
            if (responseStatusStripeCustomer.from === 'add_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    setAlert('Bank Details Added Sucessfully.please verify', 'success')

                    setIsOpen(false)
                    setBankVarify(true)
                    getBankDetails({
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        email: user.email ? user.email : '',
                        user_id: user.id ? user.id : '',
                    })
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
                setBtnLoading(false)
            }

            if (responseStatusStripeCustomer.from === 'verify_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    getBankDetails({
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        email: user.email ? user.email : '',
                        user_id: user.id ? user.id : '',
                    })
                    setBankVarify(false)
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
                setBtnLoading(false)
            }
            clearbankpayment()
        }
    }, [responseStatusStripeCustomer])
    useEffect(() => {
        getAllShippingAddress()
    }, [])
    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'upload_buyer_document') {
                if (responseStatusUser.status === 'success') {
                    setDocumentUploaded(true)
                    handleSubmit()
                } else {
                    setAlert('Upload Error Please Try Again Later!', 'error')
                }
            }
        }
    }, [responseStatusUser])
    useEffect(() => {
        if (all_shipping_address.length) {
            setSelectAddress(
                all_shipping_address.length > 1
                    ? all_shipping_address[all_shipping_address.length - 1]?.id
                    : all_shipping_address[0].id,
            )
        }
    }, [all_shipping_address])
    const flutterWaveConfig = {
        public_key: global?.pluginConfiguration?.flutterwave_payment_gateway?.client_key,
        tx_ref: `flutter-${new Date().valueOf()}`,
        amount:
            walletChecked && amountNeedTopay
                ? amountNeedTopay
                : parseFloat(
                      parseFloat(
                          shipping_fee != 0
                              ? total_payable_amount
                              : local_pick_total_payable_amount,
                      ).toFixed(2),
                  ),
        currency: 'NGN',
        payment_options: 'card, ussd',
        redirect_url: `${
            global?.pluginConfiguration?.flutterwave_payment_gateway?.redirect_url
        }/checkout/${type}${type === 'buynow' ? '' : '?id=' + invoice_id}`,
        meta: {
            token: 54,
        },
        customer: {
            name: `${user?.first_name} ${user?.last_name}`,
            email: user?.email,
        },
    }
    const handleFlutterPayment = useFlutterwave(flutterWaveConfig)
    const flutPay = () => {
        var ship_local = [],
            discount_details = [],
            need_discount = 0,
            transfer_amount_details = [],
            transfer_location = 0

        items.map((val) => {
            ship_local.push({
                id: type === 'buynow' ? val.cart_id : val.buynow_id,
                hasshipping: val.temp_hasshipping || val.hasshipping,
            })
            if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
                discount_details.push({
                    project_id: val.product_id,
                    amount: val.discount_amount,
                })
                need_discount = 1
            }
            if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                transfer_amount_details.push({
                    project_id: val.product_id,
                    amount: val.transfer_fee_location,
                })
                transfer_location = 1
            }
        })
        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            amountNeedTopay == 0 &&
            walletChecked
        ) {
            if (type === 'buynow') {
                successAfterPayCalculation({
                    cart_ids: JSON.stringify(cart_ids),
                    payment_details: {},
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
                        ? items.map((val) => {
                              return {
                                  id: type === 'buynow' ? val.cart_id : val.buynow_id,
                                  shipping_fee: val.shipping_fee,
                              }
                          })
                        : [],
                    user_id: user?.id,
                    transaction_id: params.get('transaction_id'),
                    payment_name: 'Wallet',
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                })
                return false
            }
            if (type === 'auction') {
                successAfterPayAuctionCalculation({
                    invoice_id,
                    payment_details: {},
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    need_discount: need_discount,
                    discount_details: discount_details,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    user_id: user?.id,
                    transaction_id: params.get('transaction_id'),
                    payment_name: 'Wallet',
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                })
                return false
            }
        }
        if (type === 'buynow') {
            successAfterPayCalculation({
                cart_ids: JSON.stringify(cart_ids),
                payment_details: {},
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
                    ? items.map((val) => {
                          return {
                              id: type === 'buynow' ? val.cart_id : val.buynow_id,
                              shipping_fee: val.shipping_fee,
                          }
                      })
                    : [],
                user_id: user?.id,
                transaction_id: params.get('transaction_id'),
                payment_name: 'Flutterwave',
                wallet: walletChecked,
                wallet_spend: walletSpend,
                auction_io: 1,
            })
        }
        if (type === 'auction') {
            successAfterPayAuctionCalculation({
                invoice_id,
                payment_details: {},
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                need_discount: need_discount,
                discount_details: discount_details,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                user_id: user?.id,
                transaction_id: params.get('transaction_id'),
                payment_name: 'Flutterwave',
                wallet: walletChecked,
                wallet_spend: walletSpend,
                auction_io: 1,
            })
        }
    }

    const usingwallettoupdatepayment = () => {
        setLoading(true)
        var ship_local = [],
            discount_details = [],
            need_discount = 0,
            transfer_amount_details = [],
            transfer_location = 0

        items.map((val) => {
            ship_local.push({
                id: type === 'buynow' ? val.cart_id : val.buynow_id,
                hasshipping: val.temp_hasshipping || val.hasshipping,
            })
            if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
                discount_details.push({
                    project_id: val.product_id,
                    amount: val.discount_amount,
                })
                need_discount = 1
            }
            if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                transfer_amount_details.push({
                    project_id: val.product_id,
                    amount: val.transfer_fee_location,
                })
                transfer_location = 1
            }
        })
        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            amountNeedTopay == 0 &&
            walletChecked
        ) {
            if (type === 'buynow') {
                successAfterPayCalculation({
                    cart_ids: JSON.stringify(cart_ids),
                    payment_details: {},
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
                        ? items.map((val) => {
                              return {
                                  id: type === 'buynow' ? val.cart_id : val.buynow_id,
                                  shipping_fee: val.shipping_fee,
                              }
                          })
                        : [],
                    user_id: user?.id,
                    transaction_id: params.get('transaction_id'),
                    payment_name: 'Wallet',
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                })
                return false
            }
            if (type === 'auction') {
                successAfterPayAuctionCalculation({
                    invoice_id,
                    payment_details: {},
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    need_discount: need_discount,
                    discount_details: discount_details,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    user_id: user?.id,
                    transaction_id: params.get('transaction_id'),
                    payment_name: 'Wallet',
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                })
                return false
            }
        }
    }

    const handleSubmit = async (event, buynow_check = 0) => {
        if (buynow_check == 0) {
            if (event) {
                event.preventDefault()
            }
        }

        if (cardData === 'nocard' && global.storeConfigration?.stripe_integration?.value == 1) {
            setAlert('Please Select Card And Pay!', 'error')
        }

        if (
            global?.storeConfigration?.default_delivery_state?.value &&
            all_shipping_address.length > 0
        ) {
            if (
                !global.storeConfigration.default_delivery_state.value.includes(
                    all_shipping_address.filter((val) => val.id == selectAddress)[0].state,
                )
            ) {
                setAlert(
                    `Selected Shipping State Shoud be ${global.storeConfigration.default_delivery_state.value}.`,
                    'error',
                )
                return false
            }
        }
        if (global?.storeConfigration?.restrict_shipping_states?.value) {
            let temp = global?.storeConfigration?.restrict_shipping_states?.value?.split(',')
            let restrict_state = temp.find(
                (val) =>
                    val.toLowerCase().trim() ==
                    all_shipping_address
                        ?.find((value) => value.id == selectAddress)
                        ?.state?.toLowerCase()
                        ?.trim(),
            )

            if (restrict_state) {
                return setAlert(
                    'You are in a restricted state. Purchase not allowed at the moment!',
                    'error',
                )
            }
        }
        if (global?.storeConfigration?.checkout_upload_document?.value == 1) {
            if (
                formikDocument.values.buyer_document_2.length < 1 &&
                user?.taxfile_org?.includes('{') &&
                !JSON.parse(user?.taxfile_org)?.buyer_2
            ) {
                return setAlert('Kindly upload driving Licence to continue!', 'error')
            }
            if (!documentUploaded) {
                return uploadFile()
            }
        }
        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            amountNeedTopay == 0 &&
            walletChecked
        ) {
            if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
                return flutPay()
            }
            //  else {
            //     return usingwallettoupdatepayment()
            // }
        }
        if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
            return handleFlutterPayment({
                callback: (response) => {
                    localStorage.setItem('flutterwave', JSON.stringify(response))
                },
                onClose: () => {},
            })
        }

        const regex = /^[a-zA-Z ]+$/
        const regexZip = /^[a-zA-Z0-9]*$/g
        let error = {}
        if (cardData === 'nocard') {
            if (
                isNaN(cardDetails.cardNumber) ||
                cardDetails.cardNumber.length < 14 ||
                cardDetails.cardNumber.length > 16
            ) {
                error['cardNumber'] = t('enter_val_number')
            }
            if (!cardDetails.cardName || !regex.test(cardDetails.cardName)) {
                error['cardName'] = t('enter_val_name')
            }
            if (!cardDetails.cardCountry) {
                error['cardCountry'] = t('invalid') + '!'
            }
            if (!cardDetails.cardCity || !regex.test(cardDetails.cardCity)) {
                error['cardCity'] = t('invalid') + '!'
            }
            // if (!regex.test(cardDetails.cardCity)) {
            //     error['cardCity'] = 'Only characters allowed!'
            // }
            if (!cardDetails.cardState) {
                error['cardState'] = t('invalid') + '!'
            }
            if (!cardDetails.cardAddress) {
                error['cardAddress'] = t('invalid') + '!'
            }
            if (
                cardDetails.cardZip.length === 0 ||
                !(
                    regexZip.test(cardDetails.cardZip) &&
                    cardDetails.cardZip.length < 8 &&
                    cardDetails.cardZip.length > 4
                )
            ) {
                error['cardZip'] = t('invalid') + '!'
            }
            if (
                cardDetails.expiryMonth.length === 0 ||
                isNaN(cardDetails.expiryMonth) ||
                cardDetails.expiryMonth.length > 2 ||
                cardDetails.expiryMonth.length < 2 ||
                cardDetails.expiryMonth <= 0 ||
                cardDetails.expiryMonth > 12
            ) {
                error['expiryMonth'] = t('enter_valid_month')
            }
            if (
                cardDetails.cvv.length === 0 ||
                isNaN(cardDetails.cvv) ||
                cardDetails.cvv.length > 4 ||
                cardDetails.cvv.length < 3
            ) {
                error['cvv'] = t('enter_valid_cvv')
            }
            if (
                isNaN(cardDetails.expiryYear) ||
                cardDetails.expiryYear.length === 0 ||
                (cardDetails.expiryYear.length < 2 && cardDetails.expiryYear.length > 4)
            ) {
                error['expiryYear'] = t('enter_valid_year')
            }
            if (!error['expiryMonth'] && !error['expiryYear']) {
                let month =
                    cardDetails.expiryMonth.length === 1
                        ? `${0}${cardDetails.expiryMonth}`
                        : cardDetails.expiryMonth
                let year = cardDetails.expiryYear.length === 2 ? `YY` : 'YYYY'
                if (moment(`${month}/${cardDetails.expiryYear}`, `MM/${year}`).isBefore(moment())) {
                    error['expiryYear'] = t('invalid_year')
                }
            }
            setError(error)
        }
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            if (!termschecked) {
                setAlert('Please Accept Terms And Conditions!', 'error')
                return false
            }
        }
        if (_.isEmpty(error)) {
            setLoading(true)
            let body = {}
            if (cardData === 'nocard') {
                body = {
                    // amount: parseFloat(
                    //     ((parseFloat(amount) + parseFloat(buyers_premium)) * 100).toFixed(2),
                    // ),
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: 'Amount paid from checkout page!',
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_token: true,
                    message: 'Manual Payment!',
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                    card_address_country: cardDetails?.cardCountry == '38' ? 'CA' : 'US',
                    card_address_zip: cardDetails.cardZip,
                    billing_details: {
                        email: user.email,
                        name: user.first_name,
                        address: {
                            line1: user.address1,
                            line2: user.address2,
                            city: user.city,
                            state: user.state,
                            country: user?.country == '38' ? 'CA' : 'US',
                            postal_code: user.zip,
                        },
                    },
                }
            } else {
                body = {
                    source: cardData,
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: 'Card checkout',
                }
            }
            if (saveCard && buynow_check == 0) {
                let newCard = {
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user ? user.card_paymentid : '',
                    cvv: '',
                    card_address_zip: cardDetails.cardZip,
                    card_address_country: cardDetails.cardCountry == '38' ? 'CA' : 'US',
                    is_primary: '',
                    user_id: '',
                    id: '',
                    card_token: true,
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                }
                createStripeCard(newCard)
            }
            var ship_local = [],
                discount_details = [],
                need_discount = 0,
                transfer_amount_details = [],
                transfer_location = 0,
                wallet_amount = 0,
                referel_plugin = global?.pluginConfiguration?.referral?.enable == 1 ? 1 : 0

            items.map((val) => {
                ship_local.push({
                    id: type === 'buynow' ? val.cart_id : val.buynow_id,
                    hasshipping: val.temp_hasshipping || val.hasshipping,
                })
                if (parseFloat(val?.discount_amount ? val.discount_amount : 0) > 0) {
                    discount_details.push({
                        project_id: val.product_id,
                        amount: val.discount_amount,
                        store_id: global?.storeDetails?.id ? global.storeDetails.id : '',
                        customer_id: user?.id ? user.id : '',
                        discount_id: val.discount_id,
                    })
                    need_discount = 1
                }
                if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                    transfer_amount_details.push({
                        project_id: val.product_id,
                        amount: val.transfer_fee_location,
                    })
                    transfer_location = 1
                }
            })

            if (
                global?.pluginConfiguration?.referral?.enable == 1 &&
                parseFloat(amountNeedTopay) > 0.5 &&
                walletChecked
            ) {
                wallet_amount = parseFloat(user?.wallet_amount)
                referel_plugin = 1
            } else {
                if (
                    parseFloat(amountNeedTopay) < 0.5 &&
                    parseFloat(amountNeedTopay) > 0.0 &&
                    global?.pluginConfiguration?.referral?.enable == 1 &&
                    walletChecked
                ) {
                    setAlert(
                        'Total amount should be greater than or equal to $0.50.Your total amount is $' +
                            parseFloat(amountNeedTopay).toFixed(2) +
                            'after deduction from walletamount.',
                        'error',
                    )
                    setLoading(false)
                    return false
                }
            }

            if (type === 'buynow') {
                if (buynow_check == 0) {
                    var first_time_out = Math.floor(Math.random() * (4000 - 2000))
                    setTimeout(() => {
                        buynow_make_payment({ product_id: project_id_buynow })
                    }, first_time_out)

                    return false
                } else {
                    var second_time_out = Math.floor(Math.random() * (4000 - 2000))
                    setTimeout(() => {
                        successAfterPay({
                            cart_ids: JSON.stringify(cart_ids),
                            payment_details: body,
                            shipping_addr_id: selectAddress,
                            user_country: user?.country == '38' ? 'CA' : 'US',
                            ship_local,
                            shipping_inside: shipping,
                            transfer_location: transfer_location,
                            transfer_amount_details: transfer_amount_details,
                            wallet_amount: wallet_amount,
                            referel_plugin: referel_plugin,
                            wallet: walletChecked ? 1 : 0,
                            wallet_spend: walletSpend,
                            user_id: user?.id,
                            need_discount: need_discount,
                            discount_details: discount_details,
                            shipping_amount_from_shipstation: global.pluginConfiguration?.shipping
                                ?.enable
                                ? items.map((val) => {
                                      return {
                                          id: type === 'buynow' ? val.cart_id : val.buynow_id,
                                          shipping_fee: val.shipping_fee,
                                      }
                                  })
                                : [],
                        })
                    }, second_time_out)
                }
            }
            if (type === 'auction') {
                successAfterPayAuction({
                    invoice_id,
                    payment_details: body,
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    need_discount: need_discount,
                    discount_details: discount_details,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    wallet_amount: wallet_amount,
                    referel_plugin: referel_plugin,
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                    user_id: user?.id,
                })
            }
        }
    }

    const paypalSubmit = async (event) => {
        event.preventDefault()
        if (
            global?.storeConfigration?.default_delivery_state?.value &&
            all_shipping_address.length > 0
        ) {
            if (
                !global.storeConfigration.default_delivery_state.value.includes(
                    all_shipping_address.filter((val) => val.id == selectAddress)[0].state,
                )
            ) {
                setAlert(
                    `Selected Shipping State Shoud be ${global.storeConfigration.default_delivery_state.value}.`,
                    'error',
                )
                return false
            }
        }
        // if (
        //     global?.pluginConfiguration?.referral?.enable == 1 &&
        //     amountNeedTopay == 0 &&
        //     walletChecked
        // ) {
        //     if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
        //         return flutPay()
        //     } else {
        //         return usingwallettoupdatepayment()
        //     }
        // }
        // if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
        //     return handleFlutterPayment({
        //         callback: (response) => {
        //             localStorage.setItem('flutterwave', JSON.stringify(response))
        //         },
        //         onClose: () => {},
        //     })
        // }

        // const regex = /^[a-zA-Z]+$/
        // const regexZip = /^[a-zA-Z0-9]*$/g
        // let error = {}
        // if (cardData === 'nocard') {
        //     if (
        //         isNaN(cardDetails.cardNumber) ||
        //         cardDetails.cardNumber.length < 14 ||
        //         cardDetails.cardNumber.length > 16
        //     ) {
        //         error['cardNumber'] = t('enter_val_number')
        //     }
        //     if (!cardDetails.cardName || !regex.test(cardDetails.cardName)) {
        //         error['cardName'] = t('enter_val_name')
        //     }
        //     if (!cardDetails.cardCountry) {
        //         error['cardCountry'] = t('invalid') + '!'
        //     }
        //     if (!cardDetails.cardCity || !regex.test(cardDetails.cardCity)) {
        //         error['cardCity'] = t('invalid') + '!'
        //     }
        //     // if (!regex.test(cardDetails.cardCity)) {
        //     //     error['cardCity'] = 'Only characters allowed!'
        //     // }
        //     if (!cardDetails.cardState) {
        //         error['cardState'] = t('invalid') + '!'
        //     }
        //     if (!cardDetails.cardAddress) {
        //         error['cardAddress'] = t('invalid') + '!'
        //     }
        //     if (
        //         cardDetails.cardZip.length === 0 ||
        //         !(
        //             regexZip.test(cardDetails.cardZip) &&
        //             cardDetails.cardZip.length < 8 &&
        //             cardDetails.cardZip.length > 4
        //         )
        //     ) {
        //         error['cardZip'] = t('invalid') + '!'
        //     }
        //     if (
        //         cardDetails.expiryMonth.length === 0 ||
        //         isNaN(cardDetails.expiryMonth) ||
        //         cardDetails.expiryMonth.length > 2 ||
        //         cardDetails.expiryMonth.length < 2 ||
        //         cardDetails.expiryMonth <= 0 ||
        //         cardDetails.expiryMonth > 12
        //     ) {
        //         error['expiryMonth'] = t('enter_valid_month')
        //     }
        //     if (
        //         cardDetails.cvv.length === 0 ||
        //         isNaN(cardDetails.cvv) ||
        //         cardDetails.cvv.length > 4 ||
        //         cardDetails.cvv.length < 3
        //     ) {
        //         error['cvv'] = t('enter_valid_cvv')
        //     }
        //     if (
        //         isNaN(cardDetails.expiryYear) ||
        //         cardDetails.expiryYear.length === 0 ||
        //         (cardDetails.expiryYear.length < 2 && cardDetails.expiryYear.length > 4)
        //     ) {
        //         error['expiryYear'] = t('enter_valid_year')
        //     }
        //     if (!error['expiryMonth'] && !error['expiryYear']) {
        //         let month =
        //             cardDetails.expiryMonth.length === 1
        //                 ? `${0}${cardDetails.expiryMonth}`
        //                 : cardDetails.expiryMonth
        //         let year = cardDetails.expiryYear.length === 2 ? `YY` : 'YYYY'
        //         if (moment(`${month}/${cardDetails.expiryYear}`, `MM/${year}`).isBefore(moment())) {
        //             error['expiryYear'] = t('invalid_year')
        //         }
        //     }
        //     setError(error)
        // }
        //if (_.isEmpty(error)) {
        setLoading(true)
        let body = {}
        // if (cardData === 'nocard') {
        //     body = {
        // amount: parseFloat(
        //     ((parseFloat(amount) + parseFloat(buyers_premium)) * 100).toFixed(2),
        // ),
        //         customer: user.card_paymentid,
        //         currency: user?.country == 38 ? 'cad' : 'usd',
        //         description: 'Amount paid from checkout page!',
        //         card_name: cardDetails.cardName,
        //         card_number: cardDetails.cardNumber,
        //         card_cvc: cardDetails.cvv,
        //         card_exp_month: cardDetails.expiryMonth,
        //         card_exp_year: cardDetails.expiryYear,
        //         card_token: true,
        //         message: 'Manual Payment!',
        //         card_address_line1: cardDetails.cardAddress,
        //         card_address_line2: cardDetails.cardAddress1,
        //         card_address_city: cardDetails.cardCity,
        //         card_address_state: cardDetails.cardState,
        //         card_address_country: cardDetails?.cardCountry == '38' ? 'CA' : 'US',
        //         card_address_zip: cardDetails.cardZip,
        //         billing_details: {
        //             email: user.email,
        //             name: user.first_name,
        //             address: {
        //                 line1: user.address1,
        //                 line2: user.address2,
        //                 city: user.city,
        //                 state: user.state,
        //                 country: user?.country == '38' ? 'CA' : 'US',
        //                 postal_code: user.zip,
        //             },
        //         },
        //     }
        // } else {
        //     body = {
        //         source: cardData,
        //         customer: user.card_paymentid,
        //         currency: user?.country == 38 ? 'cad' : 'usd',
        //         description: 'Card checkout',
        //     }
        //}
        // if (saveCard) {
        //     let newCard = {
        //         account_id: process.env.REACT_APP_AUCTIONPAYID,
        //         customer_id: user ? user.card_paymentid : '',
        //         cvv: '',
        //         card_address_zip: cardDetails.cardZip,
        //         card_address_country: cardDetails.cardCountry == '38' ? 'CA' : 'US',
        //         is_primary: '',
        //         user_id: '',
        //         id: '',
        //         card_token: true,
        //         card_name: cardDetails.cardName,
        //         card_number: cardDetails.cardNumber,
        //         card_cvc: cardDetails.cvv,
        //         card_exp_month: cardDetails.expiryMonth,
        //         card_exp_year: cardDetails.expiryYear,
        //         card_address_line1: cardDetails.cardAddress,
        //         card_address_line2: cardDetails.cardAddress1,
        //         card_address_city: cardDetails.cardCity,
        //         card_address_state: cardDetails.cardState,
        //     }
        //     createStripeCard(newCard)
        // }
        var ship_local = [],
            discount_details = [],
            need_discount = 0,
            transfer_amount_details = [],
            transfer_location = 0,
            wallet_amount = 0,
            referel_plugin = global?.pluginConfiguration?.referral?.enable == 1 ? 1 : 0

        items.map((val) => {
            ship_local.push({
                id: type === 'buynow' ? val.cart_id : val.buynow_id,
                hasshipping: val.temp_hasshipping || val.hasshipping,
            })
            if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
                discount_details.push({
                    project_id: val.product_id,
                    amount: val.discount_amount,
                })
                need_discount = 1
            }
            if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                transfer_amount_details.push({
                    project_id: val.product_id,
                    amount: val.transfer_fee_location,
                })
                transfer_location = 1
            }
        })

        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            parseFloat(amountNeedTopay) > 0.5 &&
            walletChecked
        ) {
            wallet_amount = parseFloat(user?.wallet_amount)
            referel_plugin = 1
        } else {
            if (
                parseFloat(amountNeedTopay) < 0.5 &&
                parseFloat(amountNeedTopay) > 0.0 &&
                global?.pluginConfiguration?.referral?.enable == 1 &&
                walletChecked
            ) {
                setAlert(
                    'Total amount should be greater than or equal to $0.50.Your total amount is $' +
                        parseFloat(amountNeedTopay).toFixed(2) +
                        'after deduction from walletamount.',
                    'error',
                )
                setLoading(false)
                return false
            }
        }

        if (type === 'buynow') {
            successAfterPaypaypalBuynow({
                cart_ids: JSON.stringify(cart_ids),
                payment_details: body,
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                wallet_amount: wallet_amount,
                referel_plugin: referel_plugin,
                wallet: walletChecked ? 1 : 0,
                wallet_spend: walletSpend,
                user_id: user?.id,
                shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
                    ? items.map((val) => {
                          return {
                              id: type === 'buynow' ? val.cart_id : val.buynow_id,
                              shipping_fee: val.shipping_fee,
                          }
                      })
                    : [],
                success_redirect_url: window.location.href + '/paypalsuccess',
                cancel_redirect_url: window.location.href + '/paypalcancel',
            })
        }
        if (type === 'auction') {
            successAfterPaypaypalAuction({
                invoice_id,
                payment_details: body,
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                need_discount: need_discount,
                discount_details: discount_details,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                wallet_amount: wallet_amount,
                referel_plugin: referel_plugin,
                wallet: walletChecked ? 1 : 0,
                wallet_spend: walletSpend,
                user_id: user?.id,
                success_redirect_url: window.location.href.includes('?')
                    ? window.location.href.split('?')[0] + '/paypalsuccess'
                    : '',
                cancel_redirect_url: window.location.href.includes('?')
                    ? window.location.href.split('?')[0] + '/paypalcancel'
                    : '',
            })
        }
        //}
    }

    useEffect(() => {
        if (params.get('transaction_id') && params.get('status') === 'successful' && user) {
            flutPay()
        }
    }, [user])
    const handleSubmitBank = async (event) => {
        event.preventDefault()
        var index_pos
        allBankData.map((val, index) => {
            if (val.id == cardData) {
                index_pos = index
            }
        })
        if (cardData === 'nocard') {
            setAlert('Please Select Bank Details', 'error')
            return false
        } else if (allBankData[index_pos].status != 'verified') {
            setAlert('Please Verifiy Bank Details', 'error')
            setBankVarify(true)
            return false
        } else {
            setLoading(true)
            transferamount({
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                user_id: user.id ? user.id : '',
                payAmount: parseInt(parseFloat(total_payable_amount) * 100),
            })
        }
    }

    const uploadFile = () => {
        const formData = new FormData()
        formikDocument.values.buyer_document_2.map((val) => formData.append('buyer_document', val))

        upload_buyer_document(formData)
    }
    const handleFileChange = (e) => {
        setUploadDocument(e.target.files[0])
    }
    useEffect(() => {
        if (responseStatusPayment) {
            if (
                responseStatusPayment.from === 'successAfterPay' ||
                responseStatusPayment.from === 'successAfterPayAuction'
            ) {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.from === 'successAfterPay') {
                        buynow_reset_payment({ product_id: project_id_buynow })
                    }
                    loadUser()
                    setOpen(true)
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                    if (responseStatusPayment.from === 'successAfterPay') {
                        buynow_reset_payment({ product_id: project_id_buynow })
                    }
                }
            }
            if (
                responseStatusPayment.from === 'successAfterPayCalculation' ||
                responseStatusPayment.from === 'successAfterPayAuctionCalculation'
            ) {
                if (responseStatusPayment.status === 'success') {
                    setOpen(true)
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }
            if (responseStatusPayment.from === 'successAfterPaypalauction') {
                if (responseStatusPayment?.status === 'success') {
                    var response_data = responseStatusPayment?.data
                    if (response_data?.redirect_url?.href) {
                        window.location.href = response_data?.redirect_url?.href
                    }
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }
            if (responseStatusPayment.from === 'successAfterPaypalbuynow') {
                if (responseStatusPayment?.status === 'success') {
                    var response_data = responseStatusPayment?.data
                    if (response_data?.redirect_url?.href) {
                        window.location.href = response_data?.redirect_url?.href
                    }
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }

            if (responseStatusPayment.from === 'buynow_make_payment') {
                if (responseStatusPayment?.status === 'success') {
                    setAlert('Sorry,Some One Has Bought Your Item.Please Try Again Later!', 'error')
                    setLoading(false)
                    buynow_reset_payment({ product_id: project_id_buynow })
                } else {
                    handleSubmit({}, 1)
                }
            }
        }
        clearResponse()
    }, [responseStatusPayment])

    const onTextChange = (e) => {
        setCardDetails({ ...cardDetails, [e.target.name]: e.target.value })
        let errorR = error
        delete errorR[e.target.name]
        setError({ ...errorR })
    }

    const bankvalidationarray = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })
    const bank_verfication_popup = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    const bank_verification_formik = useFormik({
        initialValues: {
            payAmount1: '',
            payAmount2: '',
            site_id: '',
            user_id: '',
        },
        validationSchema: bank_verfication_popup,
        onSubmit: (values) => {
            setBtnLoading(true)
            values.site_id = global.storeDetails.site_id ? global.storeDetails.site_id : ''
            values.user_id = user.id ? user.id : ''
            verifyBankdetails(values)
        },
    })

    const bankformik = useFormik({
        initialValues: {
            account_id: user?.company_name ? user.company_name : '',
            bank_country: user?.country == 38 ? 'CA' : 'us',
            bank_currency: user?.country == 38 ? 'cad' : 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_token: true,
        },
        validationSchema: bankvalidationarray,
        onSubmit: (values) => {
            setBtnLoading(true)
            var data = {
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }
            CraeteBankCustomer(data)
        },
    })
    const bank_details = {
        formik: bankformik,
        data: [
            {
                label: 'Holder Name',
                name: 'bank_account_holder_name',
                type: 'text',
                placeholder: 'Enter your bank holder name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Account Type',
                placeholder: 'Select your type',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: [
                    {
                        show: 'Individual',
                        value: 'individual',
                    },
                    {
                        show: 'Company',
                        value: 'company',
                    },
                ],
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                name: 'bank_routing_number',
                type: 'text',
                placeholder: 'Enter routing number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Account Number',
                name: 'bank_account_number',
                type: 'text',
                placeholder: 'Enter account number',
                class: 'col-12 col-sm-6',
            },
        ],
    }
    const bank_verification = {
        formik: bank_verification_formik,
        data: [
            {
                label: 'Amount 1',
                name: 'payAmount1',
                type: 'text',
                placeholder: 'Enter amount1',
                class: 'col-6',
                autoFocus: true,
            },
            {
                label: 'Amount 2',
                name: 'payAmount2',
                type: 'text',
                placeholder: 'Enter your amount1',
                class: 'col-6',
            },
        ],
    }
    const Openpopup = (e) => {
        bankformik.values.bank_account_holder_name = ''
        bankformik.values.bank_account_holder_type = ''
        bankformik.values.bank_routing_number = ''
        bankformik.values.bank_account_number = ''
        setOpen(true)
    }
    const Verifypopup = (e) => {
        setBankVarify(true)
    }
    const getLocationData = () => {
        var body = {
            countryCode: cardDetails.cardCountry,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [cardDetails.cardCountry])
    const handleShippingAddress = (e) => {
        // if (global?.storeConfigration?.restrict_shipping_states?.value) {
        //     let temp = global?.storeConfigration?.restrict_shipping_states?.value?.split(',')
        //     let restrict_state = temp.find(
        //         (val) =>
        //             val.toLowerCase().trim() ==
        //             all_shipping_address
        //                 ?.find((value) => value.id == e.target.value)
        //                 ?.state?.toLowerCase()
        //                 ?.trim(),
        //     )

        //     if (restrict_state) {
        //         return setAlert(
        //             'Cannot ship to the designated addres, Kindly select other address!',
        //             'error',
        //         )
        //     }
        // }
        setSelectAddress(e.target.value)
    }
    return (
        <div className="ptp-wrapper">
            {user ? (
                <>
                    <h2 className="od-head">{t('payment_details')}</h2>
                    <div className="chkcard">
                        <span>
                            <p>{t('total') + ' ' + t('amount')}</p>
                            <p>: {currencyFormat(amount, currency)}</p>
                        </span>
                        {buyers_premium != 0 ? (
                            <span>
                                <p>{t('buyer_premium')}</p>
                                <p>: {currencyFormat(buyers_premium, currency)}</p>
                            </span>
                        ) : null}
                        <span>
                            <p>{t('sales_tax')}</p>
                            <p>
                                :{' '}
                                {currencyFormat(
                                    shipping_fee != 0 ? sales_tax : local_pick_sales_tax,
                                    currency,
                                )}
                            </p>
                        </span>
                        {transaction_fee != 0 ? (
                            <span>
                                <p>{t('transaction_fee')}</p>
                                <p>: {currencyFormat(transaction_fee, currency)}</p>
                            </span>
                        ) : null}
                        {parseFloat(line_item_fee ? line_item_fee : 0) > 0 ? (
                            <span>
                                <p>{t('line_item_fee')}</p>
                                <p>: {currencyFormat(line_item_fee, currency)}</p>
                            </span>
                        ) : null}
                        {shipping_fee != 0 ? (
                            <span>
                                <p>{t('shipping_fee')}</p>
                                <p>: {currencyFormat(shipping_fee, currency)}</p>
                            </span>
                        ) : null}

                        {parseFloat(transfer_amount ? transfer_amount : 0) > 0 ? (
                            <span>
                                <p>{'Transfer Amount'}</p>
                                <p>: {currencyFormat(transfer_amount, currency)}</p>
                            </span>
                        ) : null}
                        {parseFloat(discount_amount ? discount_amount : 0) > 0 &&
                        global.pluginConfiguration?.coupon_code?.enable ? (
                            <span>
                                <p>{t('discount_amount')}</p>
                                <p>: {'-' + currencyFormat(discount_amount, currency)}</p>
                            </span>
                        ) : null}
                        <span>
                            <p>{t('grand_total')} </p>
                            <p>
                                :{' '}
                                {currencyFormat(
                                    shipping_fee != 0
                                        ? total_payable_amount
                                        : local_pick_total_payable_amount,
                                    currency,
                                )}
                                <CurrencyConverterFormat
                                    amount={
                                        shipping_fee != 0
                                            ? total_payable_amount
                                            : local_pick_total_payable_amount
                                    }
                                />
                            </p>
                        </span>
                        {parseFloat(deposit_amount ? deposit_amount : 0) > 0 ? (
                            <span>
                                <p>{t('deposit_amount')}</p>
                                <p>: {currencyFormat(deposit_amount, currency)}</p>
                            </span>
                        ) : null}
                    </div>
                    {global.storeConfigration?.address_book?.value == 1 &&
                    Array.isArray(items) &&
                    items.find((val) => (val.temp_hasshipping || val.hasshipping) == 1) ? (
                        <div className="od-box">
                            <CustomSelect
                                label={'Shipping Inside Metro Vancouver Region'}
                                value={shipping}
                                onChange={(e) => {
                                    var temp = items
                                    temp.map((val) => {
                                        if (val.shipping_fee != 0) {
                                            val.temp_shipping_fee = val.shipping_fee
                                            val.total_payable_amount =
                                                val.total_payable_amount - val.temp_shipping_fee
                                        } else {
                                            val.total_payable_amount =
                                                val.total_payable_amount + val.temp_shipping_fee
                                        }
                                        val.inside_vancouver = e.target.value
                                        val.shipping_fee =
                                            e.target.value == 'yes' ? val.temp_shipping_fee : 0
                                        setItems([...temp])
                                    })
                                    setShipping(e.target.value)
                                }}
                            >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </CustomSelect>
                            {shipping === 'no' && (
                                <p>{global.storeConfigration?.outside_vancouver_message?.value}</p>
                            )}
                        </div>
                    ) : null}
                    {global.storeConfigration?.checkout_upload_document?.value == 1 &&
                        user?.taxfile_org?.includes('{') &&
                        !JSON.parse(user?.taxfile_org)?.buyer_2 && (
                            <div className="co-box-two mt-3">
                                <div className="cob-top">
                                    {/* <div className="co-label">
                                        Driving Licence <i>(Mandatory)</i>
                                    </div>
                                    <span>Recommended format: .docx, .doc, .pdf, .jpg, .jpeg</span>
                                    <div className="uploadingFile">
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/msword,application/pdf,image/jpeg"
                                        />
                                    </div>
                                    <button type="button" className="pink-btn" onClick={uploadFile}>
                                        Upload
                                    </button> */}
                                    {mapData(documentShow)}
                                </div>
                            </div>
                        )}
                    {(global.storeConfigration?.address_book?.value == 1 &&
                        shipping_fee &&
                        shipping == 'yes') ||
                    global.storeConfigration?.need_ship_address_checkout?.value == 1 ? (
                        <>
                            <h2 className="od-head">Shipping Address</h2>
                            <div className="od-box">
                                <CustomSelect
                                    label={'Select Shipping Address'}
                                    value={selectAddress}
                                    onChange={(e) => handleShippingAddress(e)}
                                >
                                    {all_shipping_address.map((val) => (
                                        <option value={val.id}>{val.address}</option>
                                    ))}
                                </CustomSelect>
                                {all_shipping_address.map(
                                    (val) =>
                                        val.id == selectAddress && (
                                            <>
                                                <p>{val.address}</p>
                                                <p>{val.addres1}</p>
                                                <p>{val.city}</p>
                                                <p>
                                                    {val.state}, {val.zipcode}
                                                </p>
                                            </>
                                        ),
                                )}
                                <div className="form-group mt-2">
                                    <button
                                        type="submit"
                                        className="pink-btn"
                                        onClick={() => setAddAddressOpen(true)}
                                    >
                                        Add Address
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {global.storeConfigration?.show_profile_address?.value == 1 &&
                    shipping_fee &&
                    shipping == 'yes' ? (
                        <>
                            <h2 className="od-head">Shipping Address</h2>
                            <div className="od-box">
                                {all_shipping_address.map(
                                    (val) =>
                                        val.id == selectAddress && (
                                            <>
                                                <p>{val.address}</p>
                                                <p>{val.addres1}</p>
                                                <p>{val.city}</p>
                                                <p>
                                                    {val.state}, {val.zipcode}
                                                </p>
                                            </>
                                        ),
                                )}
                            </div>
                        </>
                    ) : null}
                    <div className="od-box mt-2">
                        {global.storeDetails.theme == 4 ? (
                            <CustomSelect
                                label={'Select Payment Option'}
                                // id={data.id}
                                value={paymentoption}
                                //autoFocus={data.autoFocus}
                                //name={data.filter ? `filters.${data.name}.value` : data.name}
                                // size={data.size}
                                onChange={(e) => {
                                    if (allBankData.length == 0 && e.target.value == 2) {
                                        setIsOpen(true)
                                        setPaymentoption(e.target.value)
                                    } else {
                                        setPaymentoption(e.target.value)
                                    }
                                }}
                                // placeholder={data.placeholder}
                                // disabled={data.disabled}
                                // onBlur={formik.handleBlur}
                                // type={data.type}
                                // error={errorCheck(data, formik)}
                                // helperText={helperText(data, formik)}
                                // upperLabel={data.upperLabel}
                            >
                                <option value={1}>Credit Card</option>
                                <option value={2}>Bank</option>
                            </CustomSelect>
                        ) : (
                            ''
                        )}
                        {global?.storeConfigration?.paypal_account_id?.value ? (
                            <div className="text-center">
                                <DummyPaypal
                                    amount={currencyFormat(
                                        shipping_fee != 0
                                            ? total_payable_amount
                                            : local_pick_total_payable_amount,
                                        currency,
                                    )}
                                />
                            </div>
                        ) : null}
                        {paymentoption == 1 ? (
                            <>
                                <form onSubmit={handleSubmit}>
                                    {global?.pluginConfiguration?.flutterwave_payment_gateway
                                        ?.enable == 1 ? null : (
                                        <>
                                            {allData &&
                                                allData.map((data, index) => (
                                                    <div key={index} className="cardSelect">
                                                        <label
                                                            htmlFor={`ritemb${index}`}
                                                            className="cardmain"
                                                        >
                                                            <div className="flx">
                                                                <p>
                                                                    {'xxxx-xxxx-xxxx-' + data.last4}
                                                                </p>
                                                                <div className="radio-item">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`ritemb${index}`}
                                                                        name="ritem"
                                                                        value={data.id}
                                                                        checked={
                                                                            cardData === data.id
                                                                        }
                                                                        onChange={onChange}
                                                                    />
                                                                    {/* <span className="vcRad"></span> */}
                                                                </div>
                                                            </div>
                                                            <div className="flx">
                                                                <p>{data.brand}</p>
                                                                <p>
                                                                    {data.exp_month}/{data.exp_year}
                                                                </p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                ))}
                                            {cardData === 'nocard' &&
                                            global.storeConfigration?.stripe_integration?.value !=
                                                1 ? (
                                                <>
                                                    <div>
                                                        <div className="form-group">
                                                            <Inputfield
                                                                title={t('card_holder_name')}
                                                                name="cardName"
                                                                type="text"
                                                                placeholder="Enter the card holder name"
                                                                onChange={onTextChange}
                                                                value={cardDetails.cardName || ''}
                                                                autoFocus={true}
                                                                msg={error.cardName || ''}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <Inputfield
                                                                title={t('card_number')}
                                                                name="cardNumber"
                                                                type="text"
                                                                placeholder="Enter your card number"
                                                                length="16"
                                                                onChange={onTextChange}
                                                                value={cardDetails.cardNumber || ''}
                                                                // autoFocus={true}
                                                                msg={error.cardNumber || ''}
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 form-group">
                                                                <Inputfield
                                                                    title={t('expiry_month')}
                                                                    name="expiryMonth"
                                                                    type="text"
                                                                    length="2"
                                                                    onChange={onTextChange}
                                                                    placeholder="MM"
                                                                    value={
                                                                        cardDetails.expiryMonth ||
                                                                        ''
                                                                    }
                                                                    msg={error.expiryMonth || ''}
                                                                />
                                                            </div>
                                                            <div className="col-6 form-group">
                                                                <Inputfield
                                                                    title={t('expiry_year')}
                                                                    name="expiryYear"
                                                                    type="text"
                                                                    length="4"
                                                                    onChange={onTextChange}
                                                                    placeholder="YYYY"
                                                                    value={
                                                                        cardDetails.expiryYear || ''
                                                                    }
                                                                    msg={error.expiryYear || ''}
                                                                />
                                                            </div>
                                                            <div className="col-6 form-group">
                                                                <Inputfield
                                                                    title={t('cvv')}
                                                                    name="cvv"
                                                                    onChange={onTextChange}
                                                                    type="text"
                                                                    length="4"
                                                                    placeholder="Enter your CVV"
                                                                    value={cardDetails.cvv || ''}
                                                                    msg={error.cvv || ''}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <Inputfield
                                                                title={
                                                                    t('card_address_line') +
                                                                    ' ' +
                                                                    '1'
                                                                }
                                                                name="cardAddress"
                                                                type="text"
                                                                placeholder="Enter your card Address Line 1"
                                                                onChange={onTextChange}
                                                                value={
                                                                    cardDetails.cardAddress || ''
                                                                }
                                                                msg={error.cardAddress || ''}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <Inputfield
                                                                title={
                                                                    t('card_address_line') +
                                                                    ' ' +
                                                                    '2'
                                                                }
                                                                name="cardAddress1"
                                                                type="text"
                                                                placeholder="Enter your card Address Line 2"
                                                                onChange={onTextChange}
                                                                value={
                                                                    cardDetails.cardAddress1 || ''
                                                                }
                                                                msg={error.cardAddress1 || ''}
                                                            />
                                                        </div>
                                                        <div className="row mt-2 pymtSlect">
                                                            <div className="col-md-6 col-sm-12 form-group mt-3">
                                                                <CustomSelect
                                                                    label={`Select country`}
                                                                    value={cardDetails.cardCountry}
                                                                    onChange={onTextChange}
                                                                    name="cardCountry"
                                                                    error={error.cardCountry || ''}
                                                                >
                                                                    <option value="">
                                                                        Select Country
                                                                    </option>
                                                                    {swapCountry(countryList).map(
                                                                        (country) => (
                                                                            <option
                                                                                value={
                                                                                    country.location_id
                                                                                }
                                                                            >
                                                                                {country.name}
                                                                            </option>
                                                                        ),
                                                                    )}
                                                                </CustomSelect>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12 form-group mt-3">
                                                                <CustomSelect
                                                                    label={`Select ${
                                                                        cardDetails.cardCountry ==
                                                                        38
                                                                            ? 'province'
                                                                            : 'state'
                                                                    }`}
                                                                    value={cardDetails.cardState}
                                                                    onChange={onTextChange}
                                                                    name="cardState"
                                                                    error={error.cardState || ''}
                                                                >
                                                                    <option value="">
                                                                        Select state
                                                                    </option>
                                                                    {stateList.map((country) => (
                                                                        <option
                                                                            value={country.name}
                                                                        >
                                                                            {country.name}
                                                                        </option>
                                                                    ))}
                                                                </CustomSelect>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12 form-group">
                                                                <Inputfield
                                                                    title={t('card_city')}
                                                                    name="cardCity"
                                                                    type="text"
                                                                    placeholder="Enter your card City"
                                                                    onChange={onTextChange}
                                                                    value={
                                                                        cardDetails.cardCity || ''
                                                                    }
                                                                    msg={error.cardCity || ''}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-sm-12 form-group">
                                                                <Inputfield
                                                                    title={`${
                                                                        cardDetails.cardCountry ==
                                                                        38
                                                                            ? 'Card postal code'
                                                                            : t('card_zIp_code')
                                                                    }`}
                                                                    name="cardZip"
                                                                    type="text"
                                                                    placeholder={`Enter your card ${
                                                                        cardDetails.cardCountry ==
                                                                        38
                                                                            ? 'postal'
                                                                            : 'zip'
                                                                    } code`}
                                                                    onChange={onTextChange}
                                                                    value={
                                                                        cardDetails.cardZip || ''
                                                                    }
                                                                    msg={error.cardZip || ''}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{ marginBottom: '20px' }}
                                                        >
                                                            <input
                                                                id="saveCard"
                                                                type="checkbox"
                                                                onChange={onCheckBoxChange}
                                                                checked={saveCard}
                                                            />
                                                            <label
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginBottom: '0px',
                                                                }}
                                                                htmlFor="saveCard"
                                                            >
                                                                {t('save_card')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : cardData === 'nocard' &&
                                              global.storeConfigration?.stripe_integration?.value ==
                                                  1 ? (
                                                <div
                                                    className="emptyCard d-flex justify-content-between flex-column"
                                                    onClick={() => setAddOpen(!addOpen)}
                                                >
                                                    <div>
                                                        <span>
                                                            <img src="/assets/svg/emptyCard.svg" />
                                                        </span>
                                                        <h2>ADD A NEW CARD</h2>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                    {global?.pluginConfiguration?.referral?.enable == 1 ? (
                                        <div>
                                            <CheckBox
                                                value={walletChecked}
                                                checked={walletChecked}
                                                onChange={handleWalletChange}
                                                label={`Use Available Wallet Amount ${currencyFormat(
                                                    user?.wallet_amount,
                                                    currency,
                                                )}`}
                                                disabled={user?.wallet_amount == 0}
                                            />{' '}
                                        </div>
                                    ) : null}
                                    {global?.storeConfigration?.checkout_terms_and_condtions
                                        ?.value == 1 ? (
                                        <div className="row align-items-center acceptTerms">
                                            <div className="col-1">
                                                <CheckBox
                                                    value={termschecked}
                                                    checked={termschecked}
                                                    disabled={loading}
                                                    onChange={(e) => {
                                                        if (
                                                            !termschecked
                                                            // e.target.type === 'checkbox'
                                                        ) {
                                                            // setPopupchecked(true)
                                                            setTermsAndConditions(true)
                                                        } else {
                                                            setDisablePay(true)
                                                            setTermschecked(false)
                                                        }
                                                        // setTermschecked(!termschecked)
                                                    }}
                                                    label={''}
                                                />
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()

                                                    setPopupchecked(true)
                                                }}
                                                className="col-10"
                                            >
                                                Please Accept{' '}
                                                <a style={{ color: 'blue' }}>Terms & Conditions</a>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="text-center form-group mt-2">
                                        <button
                                            type="submit"
                                            className="pink-btn"
                                            disabled={
                                                disablePay ||
                                                loading ||
                                                (user?.status == 'suspended' && type === 'buynow')
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : `${t('pay')} ${
                                                      user?.country == 38
                                                          ? ''
                                                          : global.storeConfigration
                                                                ?.currency_format?.value
                                                          ? global.storeConfigration.currency_format
                                                                .value
                                                          : '$'
                                                  } ${
                                                      parseFloat(
                                                          deposit_amount ? deposit_amount : 0,
                                                      ) > 0
                                                          ? deposit_amount.toUSFormat()
                                                          : shipping_fee != 0
                                                          ? total_payable_amount.toUSFormat()
                                                          : local_pick_total_payable_amount.toUSFormat()
                                                  }`}
                                        </button>
                                    </div>
                                </form>
                                {global?.pluginConfiguration?.paypal_partner?.enable == 1 &&
                                !global?.storeConfigration?.paypal_account_id?.value ? (
                                    <>
                                        <div style={{ textAlign: 'center' }}>Or</div>
                                        <div className="text-center form-group mt-2">
                                            {/* <button
                                                type="submit"
                                                className="pink-btn"
                                                onClick={paypalSubmit}
                                                disabled={loading}
                                            >
                                                {loading
                                                    ? 'Loading...'
                                                    : `${'Pay With PayPal'} ${
                                                          user?.country == 38
                                                              ? ''
                                                              : global.storeConfigration
                                                                    ?.currency_format?.value
                                                              ? global.storeConfigration
                                                                    .currency_format.value
                                                              : '$'
                                                      } ${
                                                          parseFloat(
                                                              deposit_amount ? deposit_amount : 0,
                                                          ) > 0
                                                              ? deposit_amount.toUSFormat()
                                                              : shipping_fee != 0
                                                              ? total_payable_amount.toUSFormat()
                                                              : local_pick_total_payable_amount.toUSFormat()
                                                      }`}
                                            </button> */}
                                            <button
                                                onClick={paypalSubmit}
                                                className="paypal-buy-now-button"
                                                disabled={
                                                    loading ||
                                                    (user?.status == 'suspended' &&
                                                        type === 'buynow')
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <span>Pay With</span>
                                                <svg
                                                    aria-label="PayPal"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="90"
                                                    height="33"
                                                    viewBox="34.417 0 90 33"
                                                >
                                                    <path
                                                        fill="#253B80"
                                                        d="M46.211 6.749h-6.839a.95.95 0 0 0-.939.802l-2.766 17.537a.57.57 0 0 0 .564.658h3.265a.95.95 0 0 0 .939-.803l.746-4.73a.95.95 0 0 1 .938-.803h2.165c4.505 0 7.105-2.18 7.784-6.5.306-1.89.013-3.375-.872-4.415-.972-1.142-2.696-1.746-4.985-1.746zM47 13.154c-.374 2.454-2.249 2.454-4.062 2.454h-1.032l.724-4.583a.57.57 0 0 1 .563-.481h.473c1.235 0 2.4 0 3.002.704.359.42.469 1.044.332 1.906zM66.654 13.075h-3.275a.57.57 0 0 0-.563.481l-.146.916-.229-.332c-.709-1.029-2.29-1.373-3.868-1.373-3.619 0-6.71 2.741-7.312 6.586-.313 1.918.132 3.752 1.22 5.03.998 1.177 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.91a.57.57 0 0 0 .562.66h2.95a.95.95 0 0 0 .939-.804l1.77-11.208a.566.566 0 0 0-.56-.657zm-4.565 6.374c-.316 1.871-1.801 3.127-3.695 3.127-.951 0-1.711-.305-2.199-.883-.484-.574-.668-1.392-.514-2.301.295-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.499.589.697 1.411.554 2.317zM84.096 13.075h-3.291a.955.955 0 0 0-.787.417l-4.539 6.686-1.924-6.425a.953.953 0 0 0-.912-.678H69.41a.57.57 0 0 0-.541.754l3.625 10.638-3.408 4.811a.57.57 0 0 0 .465.9h3.287a.949.949 0 0 0 .781-.408l10.946-15.8a.57.57 0 0 0-.469-.895z"
                                                    ></path>
                                                    <path
                                                        fill="#179BD7"
                                                        d="M94.992 6.749h-6.84a.95.95 0 0 0-.938.802l-2.767 17.537a.57.57 0 0 0 .563.658h3.51a.665.665 0 0 0 .656-.563l.785-4.971a.95.95 0 0 1 .938-.803h2.164c4.506 0 7.105-2.18 7.785-6.5.307-1.89.012-3.375-.873-4.415-.971-1.141-2.694-1.745-4.983-1.745zm.789 6.405c-.373 2.454-2.248 2.454-4.063 2.454h-1.031l.726-4.583a.567.567 0 0 1 .562-.481h.474c1.233 0 2.399 0 3.002.704.358.42.467 1.044.33 1.906zM115.434 13.075h-3.272a.566.566 0 0 0-.562.481l-.146.916-.229-.332c-.709-1.029-2.289-1.373-3.867-1.373-3.619 0-6.709 2.741-7.312 6.586-.312 1.918.131 3.752 1.22 5.03 1 1.177 2.426 1.666 4.125 1.666 2.916 0 4.532-1.875 4.532-1.875l-.146.91a.57.57 0 0 0 .563.66h2.949a.95.95 0 0 0 .938-.804l1.771-11.208a.57.57 0 0 0-.564-.657zm-4.565 6.374c-.314 1.871-1.801 3.127-3.695 3.127-.949 0-1.711-.305-2.199-.883-.483-.574-.666-1.392-.514-2.301.297-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.501.589.699 1.411.554 2.317zM119.295 7.23l-2.807 17.858a.569.569 0 0 0 .562.658h2.822c.469 0 .866-.34.938-.803l2.769-17.536a.57.57 0 0 0-.562-.659h-3.16a.571.571 0 0 0-.562.482z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <form onSubmit={handleSubmitBank}>
                                {allBankData &&
                                    allBankData.map((data, index) => (
                                        <div key={index} className="cardSelect">
                                            <label htmlFor="ritemb" className="cardmain">
                                                <div className="flx">
                                                    <p>{data.bank_name}</p>
                                                    <div className="radio-item">
                                                        <input
                                                            type="checkbox"
                                                            id="ritemb"
                                                            name="ritem"
                                                            value={data.id}
                                                            checked={cardData === data.id}
                                                            onChange={onChange}
                                                        />
                                                        {/* <span className="vcRad"></span> */}
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <p>{data.account_holder_name}</p>
                                                    <p>
                                                        {data.status == 'new' ||
                                                        data.status == 'verification_failed' ? (
                                                            <Button
                                                                onClick={(e) => Verifypopup(e)}
                                                                className="edit"
                                                            >
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFY
                                                            </Button>
                                                        ) : data.status == 'verified' ? (
                                                            <Button className="edit">
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFIED
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                {/* {cardData === 'nocard' && (
                                    <div>
                                        <div className="form-group">
                                            <Inputfield
                                                title="Card Number"
                                                name="cardNumber"
                                                type="text"
                                                placeholder="Enter your card number"
                                                length="16"
                                                onChange={onTextChange}
                                                value={cardDetails.cardNumber || ''}
                                                autoFocus={true}
                                                msg={error.cardNumber || ''}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Month"
                                                    name="expiryMonth"
                                                    type="text"
                                                    length="2"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Month"
                                                    value={cardDetails.expiryMonth || ''}
                                                    msg={error.expiryMonth || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Year"
                                                    name="expiryYear"
                                                    type="text"
                                                    length="4"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Year"
                                                    value={cardDetails.expiryYear || ''}
                                                    msg={error.expiryYear || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="CVV"
                                                    name="cvv"
                                                    onChange={onTextChange}
                                                    type="text"
                                                    length="4"
                                                    placeholder="Enter your CVV"
                                                    value={cardDetails.cvv || ''}
                                                    msg={error.cvv || ''}
                                                />
                                            </div>
                                        </div>
                                        <input
                                            id="saveCard"
                                            type="checkbox"
                                            onChange={onCheckBoxChange}
                                            checked={saveCard}
                                        />
                                        <label style={{ marginLeft: 10 }} htmlFor="saveCard">
                                            Save Card
                                        </label>
                                    </div>
                                )} */}
                                <div className="text-center form-group mt-2">
                                    <button
                                        type="submit"
                                        className="pink-btn"
                                        disabled={
                                            loading ||
                                            (user?.status == 'suspended' && type === 'buynow')
                                                ? true
                                                : false
                                        }
                                    >
                                        {loading
                                            ? 'Loading...'
                                            : ` Pay ${
                                                  global.storeConfigration?.currency_format?.value
                                                      ? global.storeConfigration.currency_format
                                                            .value
                                                      : ''
                                              } ${
                                                  parseFloat(deposit_amount ? deposit_amount : 0) >
                                                  0
                                                      ? deposit_amount.toUSFormat()
                                                      : shipping_fee != 0
                                                      ? total_payable_amount.toUSFormat()
                                                      : local_pick_total_payable_amount.toUSFormat()
                                              }`}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </>
            ) : null}

            <Popup
                open={isOpen}
                handleClose={(e) => setIsOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_details)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bankformik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'ADD'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>
            <Popup
                open={addAddressOpen}
                handleClose={(e) => setAddAddressOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Add Shipping Address"
            >
                <>
                    <AddressBook
                        closePopup={1}
                        closePopupFunction={(e) => setAddAddressOpen(false)}
                        refreshAddress={1}
                    />
                </>
            </Popup>
            <Popup
                open={bank_verify_open}
                handleClose={(e) => setBankVarify(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Verify Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_verification)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bank_verification_formik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'VERIFY'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>

            <Popup
                open={termsAndConditions}
                handleClose={(e) => setTermsAndConditions(false)}
                size="md"
                className="pb-3"
                modaltitle="Additional Terms And Conditions"
            >
                <div className="additionalModal">
                    <p>
                        Under penalty of perjury swear that the gaming device(s) I am purchasing
                        from OwnASlot.com is to be used only for lawful purposes, specifically for
                        home use in the State of my residency as stated in my OwnASlot.com customer
                        profile and my driver’s license. I affirm that I am TWENTY-ONE (21) YEARS OF
                        AGE OR OLDER. I will not use the gaming device(s) described herein for any
                        purpose that may violate STATE and FEDERAL LAWS.
                    </p>
                    <p>
                        am further aware that the transportation of the gaming device(s) outside my
                        state may violate STATE and FEDERAL LAWS. For and in consideration of the
                        above Statement, I do, for myself, my heirs, executors, administrators,
                        successors, and assigns, hereby release remise and forever discharge the
                        person to whom this request is presented and their agents and employees from
                        all and all manner of actions, causes of action, suits , debts, judgments,
                        executions, claims, and demands whatsoever, known or unknown, in law or
                        equity, which I ever had, now have, may have, or claim to have against or
                        employees arising out of or by reason of complying with this request
                    </p>
                    <p>
                        I agree to indemnify and hold harmless the person to whom this request is
                        presented and their agents and employees from and against all claims,
                        damages, losses, and expenses, including reasonable attorney’s fees arising
                        out of or by reason of complying with this request.
                    </p>

                    {disablePay && (
                        <button
                            className="pink-btn d-block m-auto acceptTerms"
                            onClick={() => {
                                setTermsAndConditions(false)
                                setTermschecked(true)
                                setAlert(`Accepted Terms and Conditions`, `success`)
                                setDisablePay(false)
                            }}
                        >
                            Accept
                        </button>
                    )}
                </div>
            </Popup>

            <CustomDialog
                open={popupchecked}
                function={() => setPopupchecked(false)}
                size="md"
                className="save-search-modal"
                title="Terms and Conditions"
                closeBtn={true}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: termscondtions,
                    }}
                ></div>
            </CustomDialog>

            <CustomDialog
                open={open}
                size="md"
                className="save-search-modal"
                function={handleClose}
                closeBtn={true}
                title="Order Successful"
            >
                <div className="order-success-wrap orPlcdSs text-center">
                    <img src="/image/check_circle.svg" alt="" />
                    <p className="os-text">Congratulations - You have purchased&nbsp;</p>
                    <ul className="os-text pdtNmLst">
                        {items.map((data, index) => (
                            <li>
                                <b>
                                    <span key={index}>{data.title}</span>
                                </b>
                            </li>
                        ))}
                    </ul>
                    {static_page.content && termscondtions == '' ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: static_page.content,
                            }}
                        ></div>
                    ) : (
                        <>
                            <p>
                                Shipping
                                {items.length &&
                                items.filter((ele) => ele.localpickup == 1)?.length != 0
                                    ? '/Pick-up'
                                    : ''}{' '}
                                Information will be sent to your emails inbox.
                            </p>
                            <p>To download your invoice, visit your Profile.</p>
                        </>
                    )}
                </div>
            </CustomDialog>
            <div className="PaymentModal">
                <CustomDialog
                    title={'Add New Card'}
                    open={addOpen}
                    function={() => setAddOpen(!addOpen)}
                >
                    <AddCard closePopup={() => setAddOpen(!addOpen)} />
                </CustomDialog>
            </div>
        </div>
    )
}

export default MakePaymentComponent
