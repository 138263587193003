import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../Product/context/auth/authContext'
import UserContext from '../../Product/context/user/userContext'
import axios from 'axios'
import { swapCountry } from '../commonFunctions'

function BecomeSellerFunction({
    multipleUserRole,
    title,
    subTitle,
    regpro,
    defaultCountryCode,
    termsCondition,
    theme,
    phone_country_code_must,
}) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)

    let { register, responseStatus, loadUser, isAuthenticated, user } = authContext
    let { editProfile, responseStatus: responseStatusProfile } = userContext
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const update = new URLSearchParams(window.location.search.substring(1)).get('u')
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        phone:
            theme == 'artauction'
                ? Yup.string().trim().min(10, 'invalid!').required('Required')
                : phone_country_code_must
                ? Yup.string()
                      .trim()
                      .matches(/^\+/, 'Enter country code at the start')
                      .matches(/^[+]?[-]?[^a-z][0-9]+$/, 'Only numbers are allowed')
                      .min(12, 'Invalid Number!')
                      .max(16, 'Maximum 14 characters')
                      .required('Required')
                : Yup.string()
                      .trim()
                      .matches(/^[0-9]*$/g, 'Only numbers are allowed')
                      .min(10, 'Invalid!')
                      .required('Required'),
        password: Yup.string().when('role', {
            is: 1,
            then: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
        }),
        confirmPassword: Yup.string().when('role', {
            is: 1,
            then: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords not match')
                .required('Required'),
        }),
    })
    const formik = useFormik({
        initialValues: {
            role: 1,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            status: 'unverified',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            values.become_seller_notify = 1
            if (update) {
                values.role = update ? 2 : 1
                values.become_seller = 1
                editProfile(values)
            } else {
                register(values)
            }
        },
    })
    useEffect(() => {
        if (user) {
            formik.values.first_name = update ? user.first_name : ''
            formik.values.last_name = update ? user.last_name : ''
            formik.values.email = update ? user.email : ''
            formik.values.phone = update ? user.phone : ''
            formik.values.country = user.country || ''
            formik.values.state = user.state || ''
            formik.values.city = user.city || ''
            formik.values.address1 = user.address1 || ''
            formik.values.address2 = user.address2 || ''
            formik.values.zip = user.zip || ''
            formik.values.card_profileid = user.card_profileid || ''
            formik.values.company_name = user.company_name || ''

            formik.values.buyer_document_exist = user.taxfile_org.includes('buyer')
                ? [JSON.parse(user.taxfile_org).buyer]
                : []
            formik.values.status = update ? '' : 'unverified'
        }
    }, [user])
    useEffect(() => {
        if (responseStatusProfile) {
            if (responseStatusProfile.status === 'success') {
                if (responseStatusProfile.from === 'editProfile') {
                    loadUser()
                    handleRedirectInternal(history, 'search')
                }
            }
        }
    }, [responseStatusProfile])
    let data = []

    data.push(
        {
            label: 'First name' + '*',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-12',
            autoFocus: true,
            autoComplete: 1,
        },
        {
            label: 'Last name' + '*',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your last name',
            class: 'col-12',
            autoComplete: 1,
        },
        {
            label: 'Email address' + '*',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            disabled: update ? true : false,
            autoComplete: 1,
        },
        {
            label: 'Mobile number' + '*',
            name: 'phone',
            type: 'text',
            placeholder: 'Enter your mobile number',
            class: 'col-12',
            countryCodeEditable: theme == 'artauction' ? true : false,
            enableLongNumbers: theme == 'artauction' ? 1 : 0,
            autoComplete: 1,
        },
        {
            label: 'Password' + '*',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password',
            class: `col-12 ${update ? 'd-none' : ''}`,
            autoComplete: 1,
        },
        {
            label: 'Confirm password' + '*',
            name: 'confirmPassword',
            type: 'password',
            placeholder: 'Re enter your password',
            class: `col-12 ${update ? 'd-none' : ''}`,
            autoComplete: 1,
        },
    )

    const registerInfo = {
        formik: formik,
        data,
    }

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'login')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (regpro && isAuthenticated) {
            var role = regpro.location.search.split('=')[1]
            formik.values.role = role
        }
        // if (isAuthenticated) {
        //     logout()
        // }
    }, [regpro])
    return (
        <form className="reg-step1 beSelWrpr" onSubmit={formik.handleSubmit}>
            {title && <h1>{title}</h1>}
            {subTitle && <h4>{subTitle}</h4>}
            <div className="row">{mapData(registerInfo)}</div>
            <div className="text-center form-group">
                <PrimaryButton label={update ? 'Request' : 'Submit'} type="submit" />
            </div>
        </form>
    )
}

export default BecomeSellerFunction
