import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Loader from '../../../assets/loader'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import VerticalSlider from '../../../Component/VerticalSlider'
import DonationModal from '../DonationModal'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allproducts, getAllSearchProducts } =
        productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [viewProduct, setViewProduct] = useState([])
    const [value, setValue] = useState(0)
    const [donationModal, setDonationModal] = useState(false)

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
        type: '',
    })

    const [productSearch, setProductSearch] = useState({
        sh_limit: 24,
        page: 1,
        orderby: '6',
        location: [],
        state: [],
        city: [],
        category: [],
        subcategory: [],
        condition: [],
        storedate: [],
        enddate: null,
        fromprice: 0,
        toprice: 0,
        totalpage: 5,
        lotof: '',
        searchbar: '',
        from: 'auction_only',
        closed_auction_list: 0,
    })

    useEffect(() => {
        if (value === 0) {
            setAuctionSearch({ ...auctionSearch, auc_type: 2 })
        }
        if (value === 1) {
            setAuctionSearch({ ...auctionSearch, auc_type: 1 })
        }
        if (value === 2) {
            setAuctionSearch({ ...auctionSearch, type: 'closed', auc_type: '' })
        }
    }, [value])

    useEffect(() => {
        //setLoading(true)
        //getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        //console.log('search_allproducts-->', search_allproducts)
        setLoading(false)
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        //console.log('viewProduct', viewProduct)
    }, [search_allproducts])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(productSearch, 'searchPage')
    }, [isAuthenticated, productSearch])

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search?cat=${catId}`)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <div
            className=""
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="filterPanel">
                <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                    <h4 className="fpTitle">Categories</h4>
                    {props.clearSearch && (
                        <Button onClick={() => props.clearSearch()} className="filterClear">
                            <span className="material-icons">cancel</span>
                            Clear all
                        </Button>
                    )}
                </div>
                <div className="filterAcc">
                    {filterValues[0].options.map((data, index) => (
                        <ListItem
                            button
                            className="homeCatgInner"
                            key={index}
                            onClick={() => handleClick(data.id)}
                        >
                            {data.name}
                        </ListItem>
                    ))}
                </div>
            </div>
        </div>
    )

    return (
        <Layout props={props}>
            <div className="home">
                <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                        <PrimaryButton
                            label="Get Started"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${
                                        isAuthenticated && localStorage.getItem('auction_id')
                                            ? `search/product-buyer-auction/${localStorage.getItem(
                                                  'auction_id',
                                              )}`
                                            : isAuthenticated && !localStorage.getItem('auction_id')
                                            ? 'auctions'
                                            : 'sign-up'
                                    }`,
                                )
                            }
                        />
                    </div>
                </div>
                <div
                    className={`topPdtsCtnr ${
                        viewProduct.length === 0 ? '' : 'gradBack'
                    } text-center`}
                >
                    <div className="customContainer">
                        {/* <h2>TOP PRODUCTS</h2> */}
                        <h3>Silent Auction Items</h3>

                        {loading ? (
                            <div className="text-center">
                                <Loader />
                            </div>
                        ) : viewProduct.length === 0 ? (
                            <NoRecordsFound />
                        ) : (
                            <>
                                <div className="pdtGrdCntnr">
                                    {viewProduct.map((item, index) => (
                                        <>
                                            <GridProduct
                                                noTimer
                                                noBuyNow
                                                noBidButton
                                                data={item}
                                                link={`/gallery/product-view/${item.id}`}
                                                handleClick={handleClick}
                                                timerTheme={props.timerTheme}
                                                type={props.type ? props.type : ''}
                                                cardTheme={props.cardTheme}
                                                getAllProducts={props.getAllProducts}
                                                productViewLink={
                                                    '/search/product-buyer-auction/' +
                                                    item.lotof +
                                                    '?product_id=' +
                                                    item.id
                                                }
                                            />
                                        </>
                                    ))}
                                </div>
                                <SecondaryButton
                                    btnSize="explrMrBtn mb-4"
                                    label="Explore Other Products"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            localStorage.getItem('auction_id')
                                                ? `search/product-buyer-auction/${localStorage.getItem(
                                                      'auction_id',
                                                  )}`
                                                : 'search',
                                        )
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="adBnr">
                    <div className="adCntnr">
                        <h2>Donate for a cause.</h2>
                        <PrimaryButton
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    localStorage.getItem('auction_id')
                                        ? `search/product-buyer-auction/${localStorage.getItem(
                                              'auction_id',
                                          )}?value=2`
                                        : 'auctions',
                                )
                            }
                            label="Make a donation"
                        />
                    </div>
                </div>
                <div className="customContainer auctionListCnt">
                    <div className="homeCnt row align-items-stretch">
                        <div className="actnLt col-md-8 col-lg-6 col-12">
                            {loading ? (
                                <div className="text-center">
                                    <Loader />
                                </div>
                            ) : viewAuction.length === 0 ? (
                                <NoRecordsFound />
                            ) : viewAuction.length < 4 ? (
                                <div className="actnGrid">
                                    {viewAuction.map((data, index) => (
                                        <GridAuction
                                            noActionBtn
                                            key={index}
                                            data={data}
                                            details={`search/product-buyer-auction/${data.id}`}
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <VerticalSlider auctionData={viewAuction} />
                            )}
                        </div>
                        <div className="actnRt col-md-4 col-lg-6 col-12">
                            <h2>Auctions happening right now</h2>
                            <p>
                                Charity Auctions are easy to set up and have a ton of great features
                                that make it easy to raise money,capture donor info, and minimize
                                the amount of time it takes to manage everything. Explore the active
                                events.
                                <br />
                                Let&apos;s get it started!
                            </p>
                            <SecondaryButton
                                label="Explore Events"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        localStorage.getItem('auction_id')
                                            ? `search/product-buyer-auction/${localStorage.getItem(
                                                  'auction_id',
                                              )}`
                                            : 'auction',
                                    )
                                }
                            />
                        </div>
                    </div>
                </div> */}
                {/* <div className="topPdtsCtnr text-center my-5">
                    <div className="customContainer">
                        <h2>Explore Awesome Products</h2>
                        <h3>Recently listed</h3>
                        {viewProduct.length ? (
                            <div className="pdtGrdCntnr">
                                <>
                                    {viewProduct.map((item, index) => (
                                        <GridProduct
                                            noTimer
                                            noBuyNow
                                            noBidButton
                                            data={item}
                                            link={`/gallery/product-view/${item.id}`}
                                            handleClick={handleClick}
                                            timerTheme={props.timerTheme}
                                            type={props.type ? props.type : ''}
                                            cardTheme={props.cardTheme}
                                            getAllProducts={props.getAllProducts}
                                            productViewLink={
                                                '/search/product-buyer-auction/' + item.lotof
                                            }
                                        />
                                    ))}
                                </>
                            </div>
                        ) : (
                            <div className="mt-4">
                                <NoRecordsFound />
                            </div>
                        )}
                    </div>
                </div> */}
            </div>
            <DonationModal open={donationModal} handleClose={() => setDonationModal(false)} />
        </Layout>
    )
}

export default Home
